import React, { useEffect } from 'react';
import { Button, Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities, reset } from './loan.reducer';
import NumberFormat from 'react-number-format';
import { TextFormat } from 'react-jhipster';
import { APP_DATE_FORMAT } from 'app/config/constants';

export const Loan = () => {
  const dispatch = useAppDispatch();
  const loanReportList = useAppSelector(state => state.loan.entities);
  const loading = useAppSelector(state => state.loan.loading);
  const resetAll = () => {
    dispatch(reset());
    dispatch(getEntities());
  };

  useEffect(() => {
    resetAll();
  }, []);
  const handleSyncList = () => {
    resetAll();
  };

  return (
    <div>
      <h2 id="loan-heading" data-cy="LoanHeading">
        Loans
      </h2>
      <div className="d-flex justify-content-end">

        <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
          <FontAwesomeIcon icon="sync" spin={loading} /> Refresh list
        </Button>
      </div>
      <div className="tableContainer mt-4 ">
        {loanReportList.length > 0 ? (
          <Table responsive className="table-striped table-bordered tableContainer ">
            <thead>
              <tr>
                <th>Loan Application Unique Id</th>
                <th>Loan Activation Date</th>
                <th>Loan Status</th>
                <th>Ledger Loan Unique Id</th>
                <th style={{ color: '#000', backgroundColor: '#95d5b2' }}>Borrower Id</th>
                <th style={{ color: '#000', backgroundColor: '#95d5b2' }}>Borrower Business Name</th>
                <th style={{ color: '#000', backgroundColor: '#95d5b2' }}>Borrower Business Cro Number</th>
                <th style={{ color: '#000', backgroundColor: '#95d5b2' }}>Borrower Real Bank Iban</th>
                <th>Net Loan Amount  </th>
                <th>Origination Fee Amount</th>
                <th>Total Gross Loan Amount</th>
                <th>Total Daily Fee Amount</th>
                <th>Daily Holdback Percent</th>
                <th>Expected Term</th>
                <th>Expected Daily Pos Receipt</th>
                <th>Expected Daily Holdback</th>
                <th>Loan Pos No Frixion Account Id</th>
                <th style={{ color: '#000', backgroundColor: '#99d98c' }}>Primary Lender Unique Id</th>
                <th style={{ color: '#000', backgroundColor: '#99d98c' }}>Primary Lender Name</th>
                <th style={{ color: '#000', backgroundColor: '#99d98c' }}>Primary Lender Invested Amount</th>
                <th style={{ color: '#000', backgroundColor: '#99d98c' }}>Primary Lender Total Loan Repaid</th>
                <th style={{ color: '#000', backgroundColor: '#99d98c' }}>Primary Lender Total Fee Repaid</th>
                <th style={{ color: '#000', backgroundColor: '#52b69a' }}>Secondary Lender Unique Id</th>
                <th style={{ color: '#000', backgroundColor: '#52b69a' }}>Secondary Lender Name</th>
                <th style={{ color: '#000', backgroundColor: '#52b69a' }}>Secondary Lender Invested Amount</th>
                <th style={{ color: '#000', backgroundColor: '#52b69a' }}>Secondary Lender Total Loan Repaid</th>
                <th style={{ color: '#000', backgroundColor: '#52b69a' }}>Secondary Lender Total Fee Repaid</th>
                <th>Total Loan Repaid</th>
                <th>Total Fee Repaid</th>
                <th>Total Outstanding</th>
              </tr>
            </thead>
            <tbody>
              {loanReportList?.map((loan) => (
                <tr>
                  <td>{loan?.loan_application_unique_id}</td>
                  <td>{loan?.loan_activation_date ? (
                    <TextFormat type="date" value={loan?.loan_activation_date} format={APP_DATE_FORMAT} />
                  ) : null}</td>
                  <td>{loan?.loan_status}</td>
                  <td>{loan?.ledger_loan_unique_id}</td>
                  <td style={{ color: '#000', backgroundColor: '#95d5b2' }}>{loan?.borrower_id}</td>
                  <td style={{ color: '#000', backgroundColor: '#95d5b2' }}>{loan?.borrower_business_name}</td>
                  <td style={{ color: '#000', backgroundColor: '#95d5b2' }}>{loan?.borrower_business_cro_number}</td>
                  <td style={{ color: '#000', backgroundColor: '#95d5b2' }}>{loan?.borrower_real_bank_iban}</td>
                  <td>
                    <NumberFormat
                      value={loan?.net_loan_amount}
                      thousandSeparator={true}
                      prefix={'€ '}
                      displayType={'text'}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      value={loan?.origination_fee_amount}
                      thousandSeparator={true}
                      prefix={'€ '}
                      displayType={'text'}
                    />
                  </td>

                  <td>
                    <NumberFormat
                      value={loan?.total_gross_loan_amount}
                      thousandSeparator={true}
                      prefix={'€ '}
                      displayType={'text'}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      value={loan?.total_daily_fee_amount}
                      thousandSeparator={true}
                      prefix={'€ '}
                      displayType={'text'}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      value={loan?.daily_holdback_percent}
                      thousandSeparator={true}
                      suffix={' %'}
                      displayType={'text'}
                    />
                  </td>
                  <td>{loan?.expected_term}</td>
                  <td>
                    <NumberFormat
                      value={loan?.expected_daily_pos_receipts}
                      thousandSeparator={true}
                      prefix={'€ '}
                      displayType={'text'}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      value={loan?.expected_daily_holdback}
                      thousandSeparator={true}
                      prefix={'€ '}
                      displayType={'text'}
                    />
                  </td>
                  <td>{loan?.loan_pos_nofrixion_account_id}</td>
                  <td style={{ color: '#000', backgroundColor: '#99d98c' }}>{loan?.primary_lender_unique_id}</td>
                  <td style={{ color: '#000', backgroundColor: '#99d98c' }}>{loan?.primary_lender_name}</td>
                  <td style={{ color: '#000', backgroundColor: '#99d98c' }}>
                    <NumberFormat
                      value={loan?.primary_lender_invested_amount}
                      thousandSeparator={true}
                      prefix={'€ '}
                      displayType={'text'}
                    />
                  </td>
                  <td style={{ color: '#000', backgroundColor: '#99d98c' }}>
                    <NumberFormat
                      value={loan?.primary_lender_total_loan_repaid}
                      thousandSeparator={true}
                      prefix={'€ '}
                      displayType={'text'}
                    />
                  </td>
                  <td style={{ color: '#000', backgroundColor: '#99d98c' }}>
                    <NumberFormat
                      value={loan?.primary_lender_total_Fee_repaid}
                      thousandSeparator={true}
                      prefix={'€ '}
                      displayType={'text'}
                    />
                  </td>
                  <td style={{ color: '#000', backgroundColor: '#52b69a' }}>{loan?.secondary_lender_unique_id}</td>
                  <td style={{ color: '#000', backgroundColor: '#52b69a' }}>{loan?.secondary_lender_name}</td>
                  <td style={{ color: '#000', backgroundColor: '#52b69a' }}>
                    <NumberFormat
                      value={loan?.secondary_lender_invested_amount}
                      thousandSeparator={true}
                      prefix={'€ '}
                      displayType={'text'}
                    />
                  </td>
                  <td style={{ color: '#000', backgroundColor: '#52b69a' }}>
                    <NumberFormat
                      value={loan?.secondary_lender_total_loan_repaid}
                      thousandSeparator={true}
                      prefix={'€ '}
                      displayType={'text'}
                    />
                  </td>
                  <td style={{ color: '#000', backgroundColor: '#52b69a' }}>
                    <NumberFormat
                      value={loan?.secondary_lender_total_Fee_repaid}
                      thousandSeparator={true}
                      prefix={'€ '}
                      displayType={'text'}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      value={loan?.total_loan_repaid}
                      thousandSeparator={true}
                      prefix={'€ '}
                      displayType={'text'}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      value={loan?.total_Fee_repaid}
                      thousandSeparator={true}
                      prefix={'€ '}
                      displayType={'text'}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      value={loan?.total_outstanding}
                      thousandSeparator={true}
                      prefix={'€ '}
                      displayType={'text'}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && <div className="alert alert-warning">No Loans found</div>
        )}
      </div>
    </div>
  );
};

export default Loan;
