import './header.scss';

import React, { useState, useEffect } from 'react';
import LoadingBar from 'react-redux-loading-bar';
import { Home } from './header-components';
import { AdminMenu, EntitiesMenu, AccountMenu } from '../menus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppSelector } from 'app/config/store';

export interface IHeaderProps {
  isAuthenticated: boolean;
  isAdmin: boolean;
  isReadWriteUser: boolean;
  isInProduction: boolean;
  isOpenAPIEnabled: boolean;
}

const Header = (props: IHeaderProps) => {
  const [isSidebarClosed, setIsSidebarClosed] = useState(true);
  const role = useAppSelector(state => state.authentication.role) as string;
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);

  const [userType, setUserType] = useState('');

  useEffect(() => {
    const storedRole = localStorage.getItem('roles');
    if (storedRole) {
      setUserType(storedRole);
    }
  }, [role, isAuthenticated]);

  const handleSidebarToggle = () => {
    setIsSidebarClosed(!isSidebarClosed);
    const appViewContainer = document.getElementById('app-view-container');
    appViewContainer?.classList.toggle('view-container-active');
  };


  return (
    <>
      <div className={`sidebar ${isSidebarClosed ? 'close' : ''}`}>
        <LoadingBar className="loading-bar" />
        <div className="logo-details">
          <img hidden={isSidebarClosed} className="icon_logo" src="../../../../content/images/logo-jhipster.png" width={130} />
          <FontAwesomeIcon icon="bars" className="hamburger" onClick={handleSidebarToggle} />
        </div>
        <ul id="header-tabs" className="nav-links">
          <Home isVisible={!isSidebarClosed} />
          {props.isAuthenticated && <EntitiesMenu isVisible={!isSidebarClosed} />}
          {props.isAuthenticated && props.isAdmin && <AdminMenu showOpenAPI={props.isOpenAPIEnabled} />}
          <AccountMenu isAuthenticated={props.isAuthenticated} isVisible={!isSidebarClosed} />
        </ul>
      </div>
      <div className="app-header" id="app-header">
        <div></div>
        <div data-cy="navbar" className={`navbar navbar-expand-md navbar-dark fixed-top ${isSidebarClosed ? 'navbar-active' : ''}`}>
          <div className="container-fluid">
            <div className="brand-logo navbar-brand ms-2">
              <span className="brand-title text-dark">Backoffice Application</span>
            </div>
            <ul className="navbar-nav login-name align-items-center">
              <FontAwesomeIcon icon="user-circle" size="2x" />
              <li className="nav-item ms-2">{userType === '["ROLE_READWRITE"]' ? 'Admin' : 'User'}</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
