import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities as getLoans } from 'app/entities/loan/loan.reducer';
import { getEntity } from './loan-repayment.reducer';

export const LoanRepaymentUpdate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const isNew = id === undefined;
  const updateSuccess = useAppSelector(state => state.loanRepayment.updateSuccess);
  const handleClose = () => {
    navigate('/loan-repayment');
  };
  useEffect(() => {
    if (!isNew) {
      dispatch(getEntity(id));
    }

    dispatch(getLoans());
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
 return (
    <div>

    </div>
  );
};

export default LoanRepaymentUpdate;
