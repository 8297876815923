import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './loan.reducer';

export const LoanDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const loanEntity = useAppSelector(state => state.loan.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="loanDetailsHeading">Loan</h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">Id</span>
          </dt>
          <dd>{loanEntity.id}</dd>
          <dt>
            <span id="loanId">Loan Id</span>
          </dt>
          <dd>{loanEntity.loanId}</dd>
          <dt>
            <span id="customerId">Customer Id</span>
          </dt>
          <dd>{loanEntity.customerId}</dd>
          <dt>
            <span id="loanAmount">Loan Amount</span>
          </dt>
          <dd>{loanEntity.loanAmount}</dd>
          <dt>
            <span id="totalLoanAmount">Total Loan Amount</span>
          </dt>
          <dd>{loanEntity.totalLoanAmount}</dd>
          <dt>
            <span id="originationFeePercentage">Origination Fee Percentage</span>
          </dt>
          <dd>{loanEntity.originationFeePercentage}</dd>
          <dt>
            <span id="monthlyFeePercentage">Monthly Fee Percentage</span>
          </dt>
          <dd>{loanEntity.monthlyFeePercentage}</dd>
          <dt>
            <span id="tenureInDays">Tenure In Days</span>
          </dt>
          <dd>{loanEntity.tenureInDays}</dd>
          <dt>
            <span id="tenurePeriodFrom">Tenure Period From</span>
          </dt>
          <dd>
            {loanEntity.tenurePeriodFrom ? (
              <TextFormat value={loanEntity.tenurePeriodFrom} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="tenurePeriodTo">Tenure Period To</span>
          </dt>
          <dd>
            {loanEntity.tenurePeriodTo ? <TextFormat value={loanEntity.tenurePeriodTo} type="date" format={APP_LOCAL_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="holdBackAmountPercentage">Hold Back Amount Percentage</span>
          </dt>
          <dd>{loanEntity.holdBackAmountPercentage}</dd>
          <dt>
            <span id="expectedRepaymentAmount">Expected Repayment Amount</span>
          </dt>
          <dd>{loanEntity.expectedRepaymentAmount}</dd>
          <dt>
            <span id="preparedBy">Prepared By</span>
          </dt>
          <dd>{loanEntity.preparedBy}</dd>
          <dt>
            <span id="approvedBy">Approved By</span>
          </dt>
          <dd>{loanEntity.approvedBy}</dd>
          <dt>
            <span id="preparedDate">Prepared Date</span>
          </dt>
          <dd>
            {loanEntity.preparedDate ? <TextFormat value={loanEntity.preparedDate} type="date" format={APP_LOCAL_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="approvedDate">Approved Date</span>
          </dt>
          <dd>
            {loanEntity.approvedDate ? <TextFormat value={loanEntity.approvedDate} type="date" format={APP_LOCAL_DATE_FORMAT} /> : null}
          </dd>
          <dt>Loan Product</dt>
          <dd>{loanEntity.loanProduct ? loanEntity.loanProduct.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/loan" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/loan/${loanEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
        </Button>
      </Col>
    </Row>
  );
};

export default LoanDetail;
