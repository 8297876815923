import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getLoans } from 'app/entities/loan/loan.reducer';
import { getEntity, updateEntity, createEntity } from './loan.reducer';

export const LoanUpdate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const loans = useAppSelector(state => state.loan.entities);
  const loanEntity = useAppSelector(state => state.loan.entity);
  const loading = useAppSelector(state => state.loan.loading);
  const updating = useAppSelector(state => state.loan.updating);
  const updateSuccess = useAppSelector(state => state.loan.updateSuccess);

  const handleClose = () => {
    navigate('/loan');
  };

  useEffect(() => {
    if (!isNew) {
      dispatch(getEntity(id));
    }
    dispatch(getLoans());
  }, [isNew, id, dispatch]);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess, navigate]);

  const saveEntity = values => {
    const updatedValues = {
      ...values,
      id: values.id !== undefined ? Number(values.id) : undefined,
      loanAmount: values.loanAmount !== undefined ? Number(values.loanAmount) : undefined,
      totalLoanAmount: values.totalLoanAmount !== undefined ? Number(values.totalLoanAmount) : undefined,
      originationFeePercentage: values.originationFeePercentage !== undefined ? Number(values.originationFeePercentage) : undefined,
      monthlyFeePercentage: values.monthlyFeePercentage !== undefined ? Number(values.monthlyFeePercentage) : undefined,
      tenureInDays: values.tenureInDays !== undefined ? Number(values.tenureInDays) : undefined,
      holdBackAmountPercentage: values.holdBackAmountPercentage !== undefined ? Number(values.holdBackAmountPercentage) : undefined,
      expectedRepaymentAmount: values.expectedRepaymentAmount !== undefined ? Number(values.expectedRepaymentAmount) : undefined,
    };

    const entity = {
      ...loanEntity,
      ...updatedValues,
      loanProduct: loans.find(it => it.id.toString() === values.loanProduct.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = isNew
    ? {}
    : {
        ...loanEntity,
        loanProduct: loanEntity?.loanProduct?.id,
      };

  const renderLoanProductOptions = loans
    ? loans.map(otherEntity => (
        <option value={otherEntity.id} key={otherEntity.id}>
          {otherEntity.id}
        </option>
      ))
    : null;

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="flenderApp.loan.home.createOrEditLabel" data-cy="LoanCreateUpdateHeading">
            Create or edit a Loan
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues} onSubmit={saveEntity}>
              {!isNew && <ValidatedField name="id" required readOnly id="loan-id" label="Id" validate={{ required: true }} />}
              <ValidatedField label="Loan Id" id="loan-loanId" name="loanId" data-cy="loanId" type="text" validate={{}} />
              <ValidatedField label="Customer Id" id="loan-customerId" name="customerId" data-cy="customerId" type="text" />
              <ValidatedField label="Loan Amount" id="loan-loanAmount" name="loanAmount" data-cy="loanAmount" type="text" />
              <ValidatedField
                label="Total Loan Amount"
                id="loan-totalLoanAmount"
                name="totalLoanAmount"
                data-cy="totalLoanAmount"
                type="text"
              />
              <ValidatedField
                label="Origination Fee Percentage"
                id="loan-originationFeePercentage"
                name="originationFeePercentage"
                data-cy="originationFeePercentage"
                type="text"
              />
              <ValidatedField
                label="Monthly Fee Percentage"
                id="loan-monthlyFeePercentage"
                name="monthlyFeePercentage"
                data-cy="monthlyFeePercentage"
                type="text"
              />
              <ValidatedField label="Tenure In Days" id="loan-tenureInDays" name="tenureInDays" data-cy="tenureInDays" type="text" />
              <ValidatedField
                label="Tenure Period From"
                id="loan-tenurePeriodFrom"
                name="tenurePeriodFrom"
                data-cy="tenurePeriodFrom"
                type="date"
              />
              <ValidatedField
                label="Tenure Period To"
                id="loan-tenurePeriodTo"
                name="tenurePeriodTo"
                data-cy="tenurePeriodTo"
                type="date"
              />
              <ValidatedField
                label="Hold Back Amount Percentage"
                id="loan-holdBackAmountPercentage"
                name="holdBackAmountPercentage"
                data-cy="holdBackAmountPercentage"
                type="text"
              />
              <ValidatedField
                label="Expected Repayment Amount"
                id="loan-expectedRepaymentAmount"
                name="expectedRepaymentAmount"
                data-cy="expectedRepaymentAmount"
                type="text"
              />
              <ValidatedField label="Prepared By" id="loan-preparedBy" name="preparedBy" data-cy="preparedBy" type="text" />
              <ValidatedField label="Approved By" id="loan-approvedBy" name="approvedBy" data-cy="approvedBy" type="text" />
              <ValidatedField label="Prepared Date" id="loan-preparedDate" name="preparedDate" data-cy="preparedDate" type="date" />
              <ValidatedField label="Approved Date" id="loan-approvedDate" name="approvedDate" data-cy="approvedDate" type="date" />
              <ValidatedField id="loan-loanProduct" name="loanProduct" data-cy="loanProduct" label="Loan Product" type="select">
                <option value="" key="0" />
                {renderLoanProductOptions}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/loan" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default LoanUpdate;
