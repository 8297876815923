import React, { useEffect, useState } from 'react';
import {
  Form,
  FormGroup,
  Label,
  Button,
  Col,
  Row,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,

} from 'reactstrap';
import classnames from 'classnames';
import {
  createEntity,
  getHoldBackAndOutstandingPrincipalAmount,
  updateHoldBackPercentage,
  updatePrincipalAmount,
} from './transaction-posting.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { ValidatedField } from 'react-jhipster';
import { useForm } from 'react-hook-form';
import { getBorrowerIdList, getLoanIdList } from '../loan-repayment/loan-repayment.reducer';
import { getEntities as getBorrowerUniqueID } from '../borrower/borrower.reducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NumberFormat from 'react-number-format';


const Reporting = () => {
  const dispatch = useAppDispatch();
  const [dropdownValue, setDropdownValue] = useState('');
  const [txnDate, setTxnDate] = useState('');
  const [toAccountNumber, setToAccountNumber] = useState('');
  const [fromAccountNumber, setFromAccountNumber] = useState('');
  const [lenderName, setLenderName] = useState('');
  const [amount, setAmount] = useState('');
  const [loanId, setLoanId] = useState('');
  const [borrowerName, setBorrowerName] = useState('');
  const [holdBackAndOutstandingPrincipalAmount, setHoldBackAndOutstandingPrincipalAmount] = useState<{
    principal_amount?: number;
    holdback_rate?: number;
    outstanding_interest?: number;
    outstanding_repayment_amount?: number;
  }>({});

  const borrowerNameList = useAppSelector(state => state.borrower.entities);
  const borrowerIdList = useAppSelector(state => state.loanRepayment.borrowerIdList);
  const loanIdList = useAppSelector(state => state.loanRepayment.loanList);
  const [activeTab, setActiveTab] = useState('1');
  const [borrowerId, setBorrowerId] = useState('');
  const [outstandingAmount, setOutstandingAmount] = useState<number>(0);
  const [editedOutstandingAmount, setEditedOutstandingAmount] = useState<number>(0);
  const [editedHoldBackPercentage, setEditedHoldBackPercentage] = useState<number>(0);
  const [outstandingAmountEditMode, setOutstandingAmountEditMode] = useState(false);
  const [holdBackPercentageEditMode, setHoldBackPercentageEditMode] = useState(false);
  const { register, control } = useForm();


  const handleAccountEntry = e => {
    e.preventDefault();
    const entity = {
      lender_type: dropdownValue,
      from_account_number: fromAccountNumber,
      to_account_number: toAccountNumber,
      amount: parseFloat(amount),
      date: txnDate,
      lender_name: lenderName,
    };

    dispatch(createEntity(entity));
    setDropdownValue('');
    setTxnDate('');
    setToAccountNumber('');
    setFromAccountNumber('');
    setLenderName('');
    setAmount('');
  };

  const handleFilter = async () => {
    try {
      dispatch(getHoldBackAndOutstandingPrincipalAmount(loanId)).then((response: any) => {
        if (response.payload && response.payload.data) {
          setHoldBackAndOutstandingPrincipalAmount(response.payload.data);
          const principalAmount = response.payload.data.principal_amount;
          setOutstandingAmount(principalAmount);
        }
      });
    } catch (error) {
    }
  };

  const toggleTab = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const handleBorrowerName = async event => {
    const selectedBorrowerName = event.target.value;
    setBorrowerName(selectedBorrowerName);

    try {
      await dispatch(getBorrowerIdList(selectedBorrowerName));
    } catch (error) {
    }
  };

  const handleBorrowerId = async event => {
    const selectedBorrowerId = event.target.value;
    setBorrowerId(selectedBorrowerId);

    try {
      await dispatch(getLoanIdList({ borrowerId: selectedBorrowerId }));
    } catch (error) {
    }
  };

  const handleLoanId = async event => {
    setLoanId(event.target.value);
  };

  useEffect(() => {
    dispatch(getBorrowerUniqueID({}));
  }, []);


  useEffect(() => {
    setBorrowerName('');
    setBorrowerId('');
    setLoanId('');
  }, [activeTab]);

  useEffect(() => {
    setBorrowerId('');
    setLoanId('');
  }, [borrowerName]);

  const accountCreationHandleSubmit = async () => {

  };



  const handleOutstandingAmountEditClick = () => {
    setOutstandingAmountEditMode(true);
    setEditedOutstandingAmount(0);
  };

  const handleHoldBackPercentageEditClick = () => {
    setHoldBackPercentageEditMode(true);
    handleFilter();
    setEditedHoldBackPercentage(0);
  };

  const handleSubmitOutstandingAmount = async () => {
    try {
      await dispatch(updatePrincipalAmount({ loanId: loanId, outstandingAmount: editedOutstandingAmount }));
      setOutstandingAmount(editedOutstandingAmount);
      handleFilter();
      setOutstandingAmountEditMode(false);
    } catch (error) {
    }
  };

  const handleSubmitHoldBackPercentage = async () => {
    try {
      await dispatch(updateHoldBackPercentage({ loanId: loanId, holdBackPercentage: editedHoldBackPercentage }));
      setHoldBackPercentageEditMode(false);
      handleFilter();
    } catch (error) {
    }
  };

  const handleCloseClickOutstandingAmount = () => {
    setOutstandingAmountEditMode(false);
  };

  const handleCloseClickHoldBackPercentage = () => {
    setHoldBackPercentageEditMode(false);
  };

  return (
    <div>
      <h2>Manual Ledger Posting</h2>
      <Nav tabs className="mt-5">
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => toggleTab('loanRepayment')}
            style={{
              color: activeTab === 'loanRepayment' ? '#ffffff' : 'black',
              backgroundColor: activeTab === 'loanRepayment' ? '#006494' : '#c0c0c0',
            }}
          >
            Loan Repayment
          </NavLink>
        </NavItem>
        <NavItem className="ms-2">
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => toggleTab('2')}
            style={{ color: activeTab === '2' ? '#ffffff' : 'black', backgroundColor: activeTab === '2' ? '#006494' : '#c0c0c0' }}
          >
            Non Ledger Entries
          </NavLink>
        </NavItem>
        <NavItem className="ms-2">
          <NavLink
            className={classnames({ active: activeTab === '3' })}
            onClick={() => toggleTab('3')}
            style={{ color: activeTab === '3' ? '#ffffff' : 'black', backgroundColor: activeTab === '3' ? '#006494' : '#c0c0c0' }}
          >
            Manual Account Creation
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="loanRepayment">
          <div className="d-flex mb-5 mt-5 align-items-center  ">
            <div className="d-flex mb-5 mt-5 align-items-center dropDownContainer">
              <span className="me-2 ms-1 mt-1 text-dark"><strong>Select Borrower Name : </strong></span>
              <select value={borrowerName} onChange={handleBorrowerName}>
                <option>Select Borrower</option>
                {borrowerNameList?.map((borrower, i) => (
                  <option key={i} value={borrower.borrowerBusinessName}>
                    {borrower.borrowerBusinessName}
                  </option>
                ))}
              </select>

              <span className="me-2 ms-5 mt-1 text-dark"><strong>Select Borrower ID :  </strong></span>
              <select value={borrowerId} onChange={handleBorrowerId}>
                <option>Select Borrower ID</option>
                {borrowerIdList.map((borrower_Id, index) => (
                  <option key={index} value={borrower_Id}>
                    {borrower_Id}
                  </option>
                ))}
              </select>

              <span className="me-2  ms-5 mt-1 text-dark"><strong>Select Loan ID : </strong></span>
              <select value={loanId} onChange={handleLoanId} className="me-3">
                <option value="">Select Loan</option>
                {loanIdList.map((loan_Id, i) => (
                  <option key={i} value={loan_Id}>
                    {loan_Id}
                  </option>
                ))}
              </select>
              <Button type="button" onClick={handleFilter} color="info">
                View
              </Button>
            </div>
          </div>
          <div className="d-flex">
            {holdBackAndOutstandingPrincipalAmount.holdback_rate !== undefined && holdBackAndOutstandingPrincipalAmount.holdback_rate !== null && loanId !== '' && (
              <div className="border border-2 col-md-5 ms-4 showContainer">
                <div className="mt-3 ms-4">
                  <div>
                    <span className="mb-3 ms-4">
                      <strong>Borrower Name:</strong> {borrowerName}
                    </span>
                  </div>
                  <div className="mt-3 ms-4">
                    <span>
                      <strong> Borrower ID :</strong> {borrowerId}{' '}
                    </span>
                  </div>
                  <div className="mt-3 ">
                    <span className="mb-3 ms-4">
                      <strong> Loan Id : </strong>
                      {loanId}
                    </span>
                  </div>

                  <div className="mt-3">
                    <span className="mb-3 ms-4">
                      <strong>Outstanding Repayment Amount: </strong>
                      <NumberFormat
                        value={holdBackAndOutstandingPrincipalAmount?.outstanding_repayment_amount}
                        thousandSeparator={true}
                        prefix={'€ '}
                        displayType={'text'}
                      />

                    </span>
                  </div>

                  <div className="mt-3 mb-3">
                    <span className="mb-3 ms-4 ">
                      <strong>Current HoldBack Percentage: </strong>
                      <NumberFormat
                        value={holdBackAndOutstandingPrincipalAmount.holdback_rate}
                        thousandSeparator={true}
                        suffix={' %'}
                        displayType={'text'}
                      />
                    </span>
                  </div>
                </div>
              </div>
            )}

            {outstandingAmount !== null && outstandingAmount !== 0 && loanId !== '' && (
              <div className="border border-2 col-md-6 ms-4 updateContainer">
                <div className="ms-4 mt-3 d-flex">
                  <h4>Update Details</h4>
                </div>
                <div className="mt-3 d-flex">
                  {outstandingAmountEditMode ? (
                    <div className="d-flex">
                      <span className="mb-3 ms-4">
                        <strong>Manual Repayment Amount:</strong>
                      </span>
                      <input
                        type="number"
                        name="editedOutstandingAmount"
                        value={editedOutstandingAmount === 0 ? '' : editedOutstandingAmount}
                        onChange={e => {
                          const newValue = e.target.value;
                          setEditedOutstandingAmount(newValue === '' ? 0 : parseFloat(newValue));
                        }}
                        className="ms-3 me-3"
                        style={{
                          height: '30px',
                          borderColor: '#c0c0c0',
                          borderRadius: '8px',
                        }}
                      />

                      <button
                        className="me-2"
                        onClick={handleSubmitOutstandingAmount}
                        style={{
                          height: '30px',
                          backgroundColor: 'green',
                          border: 'none',
                          color: 'white',
                          borderRadius: '5px',
                          cursor: 'pointer',
                        }}
                      >
                        Submit
                      </button>
                      <button
                        className="me-2"
                        onClick={handleCloseClickOutstandingAmount}
                        style={{
                          height: '30px',
                          backgroundColor: 'gray',
                          border: 'none',
                          color: 'white',
                          borderRadius: '5px',
                          cursor: 'pointer',
                        }}
                      >
                        X
                      </button>
                    </div>
                  ) : (
                    <div>
                      <span className="mb-3 ms-4">
                        <strong>Manual Repayment Amount: </strong>
                      </span>
                      <span className="ms-3" onClick={handleOutstandingAmountEditClick}>
                        <FontAwesomeIcon icon="pencil" />
                      </span>
                    </div>
                  )}
                </div>
                <div className="mt-3 d-flex">
                  {holdBackPercentageEditMode ? (
                    <div>
                      <span className="mb-3 ms-4">
                        <strong>New HoldBack Percentage: </strong>
                      </span>
                      <input
                        type="number"
                        name="editedHoldBackPercentage"
                        value={editedHoldBackPercentage === 0 ? '' : editedHoldBackPercentage}
                        onChange={e => setEditedHoldBackPercentage(parseFloat(e.target.value))}
                        className="ms-3 me-3"
                        style={{
                          height: '30px',
                          borderColor: '#c0c0c0',
                          borderRadius: '8px',
                        }}
                      />
                      <button
                        className="me-2"
                        color="success"
                        onClick={handleSubmitHoldBackPercentage}
                        style={{
                          height: '30px',
                          backgroundColor: 'green',
                          border: 'none',
                          color: 'white',
                          borderRadius: '5px',
                          cursor: 'pointer',
                        }}
                      >
                        Submit
                      </button>
                      <button
                        className="me-2"
                        onClick={handleCloseClickHoldBackPercentage}
                        style={{
                          height: '30px',
                          backgroundColor: 'gray',
                          border: 'none',
                          color: 'white',
                          borderRadius: '5px',
                          cursor: 'pointer',
                        }}
                      >
                        X
                      </button>
                    </div>
                  ) : (
                    <div>
                      <span className="mb-3 ms-4">
                        <strong>New HoldBack Percentage:</strong>
                      </span>
                      <span className="ms-3" onClick={handleHoldBackPercentageEditClick}>
                        <FontAwesomeIcon icon="pencil" />
                      </span>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </TabPane>
        <TabPane tabId="2">
          <div className='mainContainer'>
            <Form onSubmit={handleAccountEntry} className="mt-5">
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <div>
                      <div>
                        <span >Lender Type : </span>
                      </div>
                      <select
                        name="dropdown"
                        id="dropdown"
                        className='mt-2'
                        value={dropdownValue}
                        onChange={e => setDropdownValue(e.target.value)}>
                        <option value="">Select an option</option>
                        <option value="Flender">Flurish</option>
                        <option value="Funder">Funder</option>
                      </select>
                    </div>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="lenderName">Name : </Label><br />
                    <input
                      type="text"
                      name="lenderName"
                      id="lenderName"
                      className='posting-form-input'
                      value={lenderName}
                      onChange={e => setLenderName(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="AcNumber">From Account Number : </Label><br />
                    <input
                      type="text"
                      name="AcNumber"
                      id="AcNumber"
                      className='posting-form-input'
                      value={fromAccountNumber}
                      onChange={e => setFromAccountNumber(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="AcNumber">To Account Number : </Label><br />
                    <input
                      type="text"
                      name="AcNumber"
                      id="AcNumber"
                      className='posting-form-input'
                      value={toAccountNumber}
                      onChange={e => setToAccountNumber(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="amount">Amount : </Label><br />
                    <input
                      type="text"
                      name="amount"
                      id="amount"
                      className='posting-form-input'
                      value={amount}
                      onChange={e => setAmount(e.target.value)} />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="amount">Amount : </Label><br />
                    <input
                      type="text"
                      name="amount"
                      id="amount"
                      className='posting-form-input'
                      value={amount}
                      onChange={e => setAmount(e.target.value)} />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="txnDate">Transaction Date : </Label><br />
                    <input
                      type="date"
                      name="txnDate"
                      id="txnDate"
                      className='posting-form-input'
                      value={txnDate}
                      onChange={e => setTxnDate(e.target.value)} />
                  </FormGroup>
                </Col>
              </Row>
              <div className="d-flex justify-content-end">
                <button className="btn btn-primary" type="submit" onClick={handleAccountEntry}>
                  Submit
                </button>
              </div>
            </Form>
          </div>
        </TabPane>
        <TabPane tabId="3">
          <div className='mainContainer'>
            <Form control={control} onSubmit={accountCreationHandleSubmit} className="mt-5">
              <Row>
                <Col md="4">
                  <ValidatedField
                    label="Unique ID"
                    id="unique_id"
                    type="text"
                    name="unique_id"
                    placeholder="Unique ID"
                    register={register}
                    validate={{
                      required: { value: true, message: 'This field is required.' },
                    }}
                  />
                  <ValidatedField
                    label="Currency"
                    name="currency"
                    id="currency"
                    type="select"
                    placeholder="Currency"
                    register={register}
                    validate={{
                      required: { value: true, message: 'This field is required.' },
                    }}
                  >
                    <option value="Debit">Euros</option>
                    <option value="Credit">Pound</option>
                  </ValidatedField>
                  <ValidatedField
                    label="Real Bank Iban"
                    id="real_bank_iban"
                    type="text"
                    name="real_bank_iban"
                    placeholder="Real Bank Iban"
                    register={register}
                  />
                </Col>
                <Col md="4">
                  <ValidatedField
                    label="Account Name"
                    id="account_name"
                    type="text"
                    name="account_name"
                    placeholder="Account Name"
                    register={register}
                    validate={{
                      required: { value: true, message: 'This field is required.' },
                    }}
                  />
                  <ValidatedField
                    label="Account Balance"
                    id="account_balance"
                    type="number"
                    name="account_balance"
                    register={register}
                    placeholder="Account Balance"
                    validate={{
                      required: { value: true, message: 'This field is required.' },
                    }}
                  />
                </Col>
                <Col md="4">
                  <ValidatedField
                    label="Account type"
                    name="account_type"
                    id="account_type"
                    type="select"
                    placeholder="Account type"
                    register={register}
                    validate={{
                      required: { value: true, message: 'This field is required.' },
                    }}
                  >
                    <option value="Debit">Savings</option>
                    <option value="Credit">Current</option>
                  </ValidatedField>
                  <ValidatedField
                    label="Entity Type"
                    name="entity_type"
                    id="entity_type"
                    type="select"
                    placeholder="Entity Type"
                    register={register}
                    validate={{
                      required: { value: true, message: 'This field is required.' },
                    }}
                  >
                    <option value="Debit">Flender</option>
                    <option value="Credit">Funder</option>
                  </ValidatedField>
                </Col>
              </Row>
              <div className="d-flex flex-row-reverse">
                <Button type="submit" color="primary">
                  Create
                </Button>
              </div>
            </Form>
            <div>

            </div>
          </div>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default Reporting;
