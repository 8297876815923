import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity, updateEntity, createEntity } from './funder-details.reducer';

export const FunderDetailsUpdate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const isNew = id === undefined;
  const funderDetailsEntity = useAppSelector(state => state.funderDetails.entity);
  const loading = useAppSelector(state => state.funderDetails.loading);
  const updating = useAppSelector(state => state.funderDetails.updating);
  const updateSuccess = useAppSelector(state => state.funderDetails.updateSuccess);

  const handleClose = () => {
    navigate('/funder-details');
  };

  useEffect(() => {
    if (!isNew) {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    if (values.lenderFireAccountIcan !== undefined && typeof values.lenderFireAccountIcan !== 'number') {
      values.lenderFireAccountIcan = Number(values.lenderFireAccountIcan);
    }
    const entity = {
      ...funderDetailsEntity,
      ...values,
    };
    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
        ...funderDetailsEntity,
      };

  const renderIdField = () => {
    if (!isNew) {
      return (
        <ValidatedField name="id" required readOnly id="funder-details-id" label="Id" validate={{ required: true }} />
      );
    }
    return null;
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="flenderApp.funderDetails.home.createOrEditLabel" data-cy="FunderDetailsCreateUpdateHeading">
            Create or edit a Funder Details
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {renderIdField()}
              <ValidatedField
                label="Lender Unique Id"
                id="funder-details-lenderUniqueId"
                name="lenderUniqueId"
                data-cy="lenderUniqueId"
                type="text"
              />
              <ValidatedField
                label="Lender Business Name"
                id="funder-details-lenderBusinessName"
                name="lenderBusinessName"
                data-cy="lenderBusinessName"
                type="text"
              />
              <ValidatedField
                label="Lender Business Email"
                id="funder-details-lenderBusinessEmail"
                name="lenderBusinessEmail"
                data-cy="lenderBusinessEmail"
                type="text"
              />
              <ValidatedField
                label="Lender Business Represent Person Name"
                id="funder-details-lenderBusinessRepresentPersonName"
                name="lenderBusinessRepresentPersonName"
                data-cy="lenderBusinessRepresentPersonName"
                type="text"
              />
              <ValidatedField
                label="Lender Fire Account Ican"
                id="funder-details-lenderFireAccountIcan"
                name="lenderFireAccountIcan"
                data-cy="lenderFireAccountIcan"
                type="text"
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/funder-details" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default FunderDetailsUpdate;
