import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import FunderDetails from './funder-details';
import FunderDetailsDetail from './funder-details-detail';
import FunderDetailsUpdate from './funder-details-update';
import FunderDetailsDeleteDialog from './funder-details-delete-dialog';

const FunderDetailsRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<FunderDetails />} />
    <Route path="new" element={<FunderDetailsUpdate />} />
    <Route path=":id">
      <Route index element={<FunderDetailsDetail />} />
      <Route path="edit" element={<FunderDetailsUpdate />} />
      <Route path="delete" element={<FunderDetailsDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default FunderDetailsRoutes;
