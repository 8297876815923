import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Modal } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity } from './loan-repayment.reducer';

export const LoanRepaymentDeleteDialog = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const [loadModal, setLoadModal] = useState(false);
  useEffect(() => {
    dispatch(getEntity(id));
    setLoadModal(true);
  }, []);
  const updateSuccess = useAppSelector(state => state.loanRepayment.updateSuccess);
  const handleClose = () => {
    navigate('/loan-repayment');
  };
  useEffect(() => {
    if (updateSuccess && loadModal) {
      handleClose();
      setLoadModal(false);
    }
  }, [updateSuccess]);
  return (
    <Modal isOpen toggle={handleClose}>
    </Modal>
  );
};

export default LoanRepaymentDeleteDialog;
