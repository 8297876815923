import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Table, Collapse, Card, CardBody } from 'reactstrap';
import { getPaginationState, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook } from '@fortawesome/free-solid-svg-icons';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getBorrowerUniqueID } from '../borrower/borrower.reducer';
import {
  getEntities,
  reset,
  getLoanIdList,
  getBorrowerIdList,
  getExcelGenerateEntities,
  getPDFGenerateEntities,
} from './loan-repayment.reducer';
import Pagination from 'react-bootstrap/Pagination';
require('jspdf-autotable');
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { APP_DATE_FORMAT } from 'app/config/constants';

export const LoanRepayment = () => {
  const dispatch = useAppDispatch();
  const [borrowerId, setBorrowerId] = useState('');
  const [loanId, setLoanId] = useState('');
  const pageLocation = useLocation();
  const [borrowerName, setBorrowerName] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 40;
  const [filterNonZero, setFilterNonZero] = useState(false);
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, itemsPerPage, 'id'), pageLocation.search),
  );
  const [sorting, setSorting] = useState(false);

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');

  const borrowerNameList = useAppSelector(state => state.borrower.entities);
  const loanRepaymentList = useAppSelector(state => state.loanRepayment.entities);
  const loanIdList = useAppSelector(state => state.loanRepayment.loanList);
  const loading = useAppSelector(state => state.loanRepayment.loading);
  const updateSuccess = useAppSelector(state => state.loanRepayment.updateSuccess);
  const borrowerIdList = useAppSelector(state => state.loanRepayment.borrowerIdList);
  const repaymentReportList = useAppSelector(state => state.loanRepayment.excelGenerateEntities);
  console.log('repaymentReportList', repaymentReportList);

  const totalPages = loanRepaymentList?.totalPages;
  const resetAll = () => {
    dispatch(reset());
    setPaginationState({
      ...paginationState,
      activePage: 1,
    });
    dispatch(getEntities({}));
  };
  useEffect(() => {
    getAllEntities();
  }, [paginationState]);

  useEffect(() => {
    dispatch(getBorrowerUniqueID({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      resetAll();
    }
  }, [updateSuccess]);

  useEffect(() => {
    getAllEntities();
  }, [paginationState.activePage]);

  useEffect(() => {
    if (sorting) {
      getAllEntities();
      setSorting(false);
    }
  }, [sorting]);

  useEffect(() => {
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
      itemsPerPage: itemsPerPage,
    });
  }, [currentPage, itemsPerPage]);

  const handleBorrowerId = async event => {
    const selectedBorrowerId = event.target.value;
    setBorrowerId(selectedBorrowerId);

    try {
      await dispatch(getLoanIdList({ borrowerId: selectedBorrowerId }));
    } catch (error) {
      console.error('Error fetching loans:', error);
    }
  };

  const handlePageClick = pageNumber => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };
  const handleBorrowerName = async event => {
    const selectedBorrowerName = event.target.value;
    setBorrowerName(selectedBorrowerName);
    setCurrentPage(1);
    dispatch(reset());
    try {
      await dispatch(getBorrowerIdList(selectedBorrowerName));
    } catch (error) {
      console.error('Error fetching borrower ID list:', error);
    }
  };
  const handleLoanId = async event => {
    setLoanId(event.target.value);
    setCurrentPage(1);
  };

  const getAllEntities = async () => {
    try {
      console.log('Fetching data...');

      let adjustedStartDate = startDate;
      let adjustedEndDate = endDate;

      if (startDate && !startTime) {
        adjustedStartDate += 'T00:00:00';
      } else if (startDate && startTime) {
        adjustedStartDate += `T${moment(startTime, 'HH:mm').format('HH:mm:ss')}`;
      }

      if (endDate && !endTime) {
        adjustedEndDate += 'T23:59:59';
      } else if (endDate && endTime) {
        adjustedEndDate += `T${moment(endTime, 'HH:mm').format('HH:mm:ss')}`;
      }

      if (!adjustedStartDate || !adjustedEndDate) {
        adjustedStartDate = '';
        adjustedEndDate = '';
      }

      if (borrowerId && loanId && currentPage && itemsPerPage) {
        await dispatch(
          getEntities({
            borrowerId: borrowerId,
            loanId: loanId,
            index: currentPage - 1,
            count: itemsPerPage,
            startDate: adjustedStartDate,
            endDate: adjustedEndDate,
            zeroFilter: filterNonZero,
          }),
        );
      } else {
        console.error('Required parameters are undefined.');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  console.log('zeroFilter', filterNonZero);

  const handleViewList = event => {
    event.preventDefault();
    getAllEntities();
  };

  const handleGenerateExcelReport = async () => {
    try {
      await dispatch(
        getExcelGenerateEntities({
          loanId: loanId,
        }),
      );
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleDownloadPdf = async () => {
    try {
      await dispatch(
        getPDFGenerateEntities({
          loanId: loanId,
        }),
      );
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const MAX_VISIBLE_PAGES = 6;

  const renderPaginationItems = () => {
    const items = [];
    const startPage = Math.max(1, currentPage - Math.floor(MAX_VISIBLE_PAGES / 2));
    const endPage = Math.min(totalPages, startPage + MAX_VISIBLE_PAGES - 1);

    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageClick(i)}>
          {i}
        </Pagination.Item>,
      );
    }

    return items;
  };

  const renderPagination = () => {
    if (totalPages <= 1) return null;

    return (
      <Pagination>
        <Pagination.First onClick={() => handlePageClick(1)} disabled={currentPage === 1} />
        <Pagination.Prev onClick={() => handlePageClick(currentPage - 1)} disabled={currentPage === 1} />
        {renderPaginationItems()}
        <Pagination.Next onClick={() => handlePageClick(currentPage + 1)} disabled={currentPage === totalPages} />
        <Pagination.Last onClick={() => handlePageClick(totalPages)} disabled={currentPage === totalPages} />
      </Pagination>
    );
  };

  return (
    <div>
      <h2 id="loan-repayment-heading" data-cy="LoanRepaymentHeading">
        Borrower Repayment Report
      </h2>
      <div>
        {!loading && loanRepaymentList?.content && loanRepaymentList?.content?.length > 0 ? (
          <div className="d-flex justify-content-end dropDownContainer">
            <Button className="me-2" color="primary" onClick={handleGenerateExcelReport} disabled={loading}>
              <FontAwesomeIcon icon={faBook} spin={loading} /> Generate Flurish Report
            </Button>
            <Button className="me-2" color="primary" onClick={handleDownloadPdf} disabled={loading}>
              <FontAwesomeIcon icon={faBook} spin={loading} /> Generate Customer Report
            </Button>
          </div>
        ) : (
          <div></div>
        )}
        <div className="d-flex mb-5 mt-5 align-items-center dropDownContainer">
          <span className="me-2 ms-1 mt-1 text-dark">
            <strong> Borrower Name : </strong>
          </span>
          <select value={borrowerName} onChange={handleBorrowerName}>
            <option>Select Borrower</option>
            {borrowerNameList?.map((borrower, i) => (
              <option key={i} value={borrower.borrowerBusinessName}>
                {borrower.borrowerBusinessName}
              </option>
            ))}
          </select>

          <span className="me-2 ms-5 mt-1 text-dark">
            <strong> Borrower ID : </strong>
          </span>
          <select value={borrowerId} onChange={handleBorrowerId}>
            <option>Select Borrower ID</option>
            {borrowerIdList.map((borrower_Id, index) => (
              <option key={index} value={borrower_Id}>
                {borrower_Id}
              </option>
            ))}
          </select>

          <span className="me-2  ms-5 mt-1 text-dark">
            <strong> Loan ID : </strong>
          </span>
          <select value={loanId} onChange={handleLoanId} className="me-3">
            <option value="">Select Loan</option>
            {loanIdList.map((loan_Id, i) => (
              <option key={i} value={loan_Id}>
                {loan_Id}
              </option>
            ))}
          </select>
          <Button type="button" onClick={handleViewList} color="info">
            View
          </Button>
        </div>
        <div className="d-flex mb-5 align-items-center dropDownContainer">
          <span color="primary" className="me-5 collapse-text" onClick={() => setIsCollapseOpen(!isCollapseOpen)}>
            {isCollapseOpen ? 'Hide' : 'Show'} Optional Filters
          </span>
          <Collapse isOpen={isCollapseOpen}>
            <Card>
              <CardBody>
                <div className="row d-flex">
                  <div className="col-md-3">
                    <label>Start Date:</label>
                    <input className="form-control" type="date" value={startDate} onChange={e => setStartDate(e.target.value)} />
                  </div>
                  <div className="col-md-3">
                    <label>End Date:</label>
                    <input className="form-control" type="date" value={endDate} onChange={e => setEndDate(e.target.value)} />
                  </div>
                  <div className="col-md-3">
                    <label>Start Time:</label>
                    <input className="form-control" type="time" value={startTime} onChange={e => setStartTime(e.target.value)} />
                  </div>
                  <div className="col-md-3">
                    <label>End Time:</label>
                    <input className="form-control" type="time" value={endTime} onChange={e => setEndTime(e.target.value)} />
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col">
                    <label htmlFor="filterNonZero">
                      <input
                        type="checkbox"
                        id="filterNonZero"
                        checked={filterNonZero}
                        onChange={event => setFilterNonZero(event.target.checked)}
                      />{' '}
                      Non-Zero Total Pos Receipts
                    </label>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Collapse>
        </div>
      </div>

      <div className="table-responsive ">
        {loading && <div className="loader">Loading ...</div>}
        {!loading && loanRepaymentList?.content && loanRepaymentList?.content?.length > 0 ? (
          <div className="mainContainer">
            <Table responsive className="table-striped table-bordered  ">
              <thead className="thead-secondary">
                <tr>
                  <th className="hand">Id</th>
                  {/* <th>
                    Borrower Id
                  </th>
                  <th>
                    Loan Id
                  </th> */}
                  <th className="hand">Date</th>
                  <th className="hand">Repayment Balance Opening</th>
                  <th className="hand">Total Pos Receipts</th>
                  <th className="hand">Passed Through</th>
                  <th className="hand">Holdback Amount</th>
                  <th className="hand" style={{ color: '#000', backgroundColor: '#99d98c' }}>
                    Funder Daily Fee Opening
                  </th>
                  <th className="hand" style={{ color: '#000', backgroundColor: '#99d98c' }}>
                    Funder Daily Fee Closing
                  </th>
                  <th className="hand" style={{ color: '#000', backgroundColor: '#99d98c' }}>
                    Funder Principal Paid
                  </th>
                  <th className="hand" style={{ color: '#000', backgroundColor: '#99d98c' }}>
                    Funder Daily Fee Paid
                  </th>

                  <th className="hand" style={{ color: '#000', backgroundColor: '#52b69a' }}>
                    Flurish Daily Fee Opening
                  </th>
                  <th className="hand" style={{ color: '#000', backgroundColor: '#52b69a' }}>
                    Flurish Daily Fee Closing
                  </th>

                  <th className="hand" style={{ color: '#000', backgroundColor: '#52b69a' }}>
                    Flurish Principal Paid
                  </th>
                  <th className="hand" style={{ color: '#000', backgroundColor: '#52b69a' }}>
                    Flurish Daily Fee Paid
                  </th>
                  <th className="hand">Total Daily Fee Paid</th>
                  <th className="hand">Total Principal Paid</th>
                  <th className="hand">Repayment Balance Closing</th>
                  <th className="hand">Repayment Type</th>
                  {/* <th className="hand">Origination Fee Paid</th> */}
                </tr>
              </thead>
              <tbody>
                {loanRepaymentList?.content?.map((loanRepayment, i) => (
                  <tr key={`entity-${i}`} data-cy="entityTable">
                    <td>{loanRepayment?.id}</td>
                    <td>
                      {loanRepayment?.dateTime ? (
                        <TextFormat type="date" value={loanRepayment?.dateTime} format={APP_DATE_FORMAT} />
                      ) : null}
                    </td>
                    <td>
                      <NumberFormat
                        value={loanRepayment?.repaymentBalanceOpening !== null ? loanRepayment?.repaymentBalanceOpening : 0}
                        thousandSeparator={true}
                        prefix={'€ '}
                        displayType={'text'}
                      />
                    </td>
                    <td>
                      <NumberFormat
                        value={loanRepayment?.totalPosReceipts !== null ? loanRepayment?.totalPosReceipts : 0}
                        thousandSeparator={true}
                        prefix={'€ '}
                        displayType={'text'}
                      />
                    </td>

                    <td>
                      <NumberFormat
                        value={loanRepayment?.passedThrough !== null ? loanRepayment?.passedThrough : 0}
                        thousandSeparator={true}
                        prefix={'€ '}
                        displayType={'text'}
                      />
                    </td>
                    <td>
                      <NumberFormat
                        value={loanRepayment?.appliedToLoan !== null ? loanRepayment?.appliedToLoan : 0}
                        thousandSeparator={true}
                        prefix={'€ '}
                        displayType={'text'}
                      /></td>

                    <td style={{ color: '#000', backgroundColor: '#99d98c', fontWeight: 'bold' }}>
                      <NumberFormat
                        value={loanRepayment?.primaryLenderDailyFeeOpening !== null ? loanRepayment?.primaryLenderDailyFeeOpening : 0}
                        thousandSeparator={true}
                        prefix={'€ '}
                        displayType={'text'}
                      />
                    </td>
                    <td style={{ color: '#000', backgroundColor: '#99d98c', fontWeight: 'bold' }}>
                      <NumberFormat
                        value={loanRepayment?.primaryLenderDailyFeeClosing !== null ? loanRepayment?.primaryLenderDailyFeeClosing : 0}
                        thousandSeparator={true}
                        prefix={'€ '}
                        displayType={'text'}
                      />
                    </td>
                    <td style={{ color: '#000', backgroundColor: '#99d98c', fontWeight: 'bold' }}>
                      <NumberFormat
                        value={loanRepayment?.primaryLenderPrincipalPaid !== null ? loanRepayment?.primaryLenderPrincipalPaid : 0}
                        thousandSeparator={true}
                        prefix={'€ '}
                        displayType={'text'}
                      />
                    </td>
                    <td style={{ color: '#000', backgroundColor: '#99d98c', fontWeight: 'bold' }}>
                      <NumberFormat
                        value={loanRepayment?.primaryLenderDailyFeePaid !== null ? loanRepayment?.primaryLenderDailyFeePaid : 0}
                        thousandSeparator={true}
                        prefix={'€ '}
                        displayType={'text'}
                      />
                    </td>
                    <td style={{ color: '#000', backgroundColor: '#52b69a', fontWeight: 'bold' }}>
                      <NumberFormat
                        value={loanRepayment?.secondaryLenderDailyFeeOpening !== null ? loanRepayment?.secondaryLenderDailyFeeOpening : 0}
                        thousandSeparator={true}
                        prefix={'€ '}
                        displayType={'text'}
                      />
                    </td>
                    <td style={{ color: '#000', backgroundColor: '#52b69a', fontWeight: 'bold' }}>
                      <NumberFormat
                        value={loanRepayment?.secondaryLenderDailyFeeClosing !== null ? loanRepayment?.secondaryLenderDailyFeeClosing : 0}
                        thousandSeparator={true}
                        prefix={'€ '}
                        displayType={'text'}
                      />
                    </td>
                    <td style={{ color: '#000', backgroundColor: '#52b69a', fontWeight: 'bold' }}>
                      <NumberFormat
                        value={loanRepayment?.secondaryLenderPrincipalPaid !== null ? loanRepayment?.secondaryLenderPrincipalPaid : 0}
                        thousandSeparator={true}
                        prefix={'€ '}
                        displayType={'text'}
                      />
                    </td>
                    <td style={{ color: '#000', backgroundColor: '#52b69a', fontWeight: 'bold' }}>
                      <NumberFormat
                        value={loanRepayment?.secondaryLenderDailyFeePaid !== null ? loanRepayment?.secondaryLenderDailyFeePaid : 0}
                        thousandSeparator={true}
                        prefix={'€ '}
                        displayType={'text'}
                      />
                    </td>
                    <td>
                      <NumberFormat
                        value={loanRepayment?.totalDailyFeePaid !== null ? loanRepayment?.totalDailyFeePaid : 0}
                        thousandSeparator={true}
                        prefix={'€ '}
                        displayType={'text'}
                      />
                    </td>
                    <td>
                      <td>
                        <NumberFormat
                          value={loanRepayment?.totalPrincipalPaid !== null ? loanRepayment?.totalPrincipalPaid : 0}
                          thousandSeparator={true}
                          prefix={'€ '}
                          displayType={'text'}
                        />
                      </td></td>
                    <td>
                      <NumberFormat
                        value={loanRepayment?.repaymentBalanceClosing !== null ? loanRepayment?.repaymentBalanceClosing : 0}
                        thousandSeparator={true}
                        prefix={'€ '}
                        displayType={'text'}
                      />
                    </td>
                    <td>{loanRepayment?.repaymentType}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        ) : (
          !loading && <div className="alert alert-warning showContainer">No Loan Repayments found</div>
        )}
      </div>
      <div className="d-flex justify-content-center mt-3">{renderPagination()}</div>
    </div>
  );
};

export default LoanRepayment;
