import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLocation } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { getPaginationState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { fetchLenderNames, getEntities, reset } from './loan-funder.reducer';
import Pagination from 'react-bootstrap/Pagination';
import { faBook } from '@fortawesome/free-solid-svg-icons';
import { downloadExcel } from 'react-export-table-to-excel';
const jsPDF = require('jspdf').default;
require('jspdf-autotable');
import NumberFormat from 'react-number-format';

export const LoanFunder = () => {
  const dispatch = useAppDispatch();
  const pageLocation = useLocation();
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );
  const [currentPage, setCurrentPage] = useState(1);
  const loanFunderList = useAppSelector(state => state.loanFunder.entities);
  const loading = useAppSelector(state => state.loanFunder.loading);
  const links = useAppSelector(state => state.loanFunder.links);
  const updateSuccess = useAppSelector(state => state.loanFunder.updateSuccess);
  const totalPages = loanFunderList?.totalPages;
  const allLenderNames = useAppSelector(state => state.loanFunder.allLenderNames);
  const [selectedLenderName, setSelectedLenderName] = useState('');
  const getAllEntities = () => {
    dispatch(
      getEntities({
        index: currentPage - 1,
        count: paginationState.itemsPerPage,
        businessName: selectedLenderName,
      }),
    );
  };
  const getLenderNames = () => {
    dispatch(fetchLenderNames());
  };

  const resetAll = () => {
    dispatch(reset());
    setPaginationState({
      ...paginationState,
      activePage: 1,
    });
    dispatch(getEntities({}));
  };

  useEffect(() => {
    if (updateSuccess) {
      resetAll();
    }
  }, [updateSuccess]);

  useEffect(() => {
    getAllEntities();
  }, [paginationState]);

  const handleLoadMore = () => {
    if ((window as any).pageYOffset > 0) {
      setPaginationState({
        ...paginationState,
        activePage: paginationState.activePage + 1,
      });
    }
  };

  const handleLoadLenderList = () => {
    getAllEntities();
  };

  const handlePageClick = pageNumber => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
      setPaginationState({
        ...paginationState,
        activePage: pageNumber,
      });
    }
  };

  const handleLenderName = async event => {
    const selectedName = event.target.value;
    setSelectedLenderName(selectedName);
    setCurrentPage(1);
  };

  useEffect(() => {
    getAllEntities();
    getLenderNames();
  }, []);

  const handleDownloadExcel = () => {
    const body = [];
    loanFunderList?.content?.map((loanFunder, i) => {
      body.push([
        loanFunder.lender_flurish_unique_id,
        loanFunder.lender_business_name,
        loanFunder.loan_application_unique_id,
        loanFunder.ledger_loan_account_id,
        loanFunder.total_invested,
        loanFunder.total_principal_repaid,
        loanFunder.total_daily_fee_repaid,
        loanFunder.total_outstanding,
      ]);
    });
    downloadExcel({
      fileName: 'Lender Profile',
      sheet: 'Lender Profile',
      tablePayload: {
        header,
        body,
      },
    });
  };
  const header = [
    'Lender Flurish Unique ID',
    'Lender Business Name',
    'Loan Application Unique ID',
    'Ledger Loan Unique ID',
    'Total Invested',
    'Total Principal Repaid',
    'Total Fee Repaid',
    'Total Outstanding',
  ];

  const handleDownloadPdf = () => {
    const doc = new jsPDF();

    doc.setTextColor(0, 0, 0);
    doc.setFontSize(15);
    doc.text('Lender Profile', 10, 10);

    const marginLeft = 2;
    const marginRight = 2;

    doc.autoTable({
      head: [['Lender Flurish Unique ID', 'Lender Business Name', 'Loan Application Unique ID', 'Ledger Loan Unique ID', 'Total Invested', 'Total Principal Repaid', 'Total Fee Repaid', 'Total Outstanding',]],
      body: loanFunderList?.content?.map(loanFunder => [loanFunder.lender_flurish_unique_id, loanFunder.lender_business_name,
      loanFunder.loan_application_unique_id, loanFunder.ledger_loan_account_id, loanFunder.total_invested, loanFunder.total_principal_repaid, loanFunder.total_daily_fee_repaid, loanFunder.total_outstanding,]),
      headStyles: { fillColor: [100, 100, 100] },
      margin: { top: 25, bottom: 2, left: marginLeft, right: marginRight },
      styles: { fontSize: 8 },
    });

    doc.save('Lender Profile.pdf');
  };

  return (
    <div>
      <h2 id="loan-funder-heading" data-cy="LoanFunderHeading">
        Lender Profile
        <div className="d-flex justify-content-end   mb-5">
          <Button className="me-2 generate-pdf-btn" color="primary" onClick={handleDownloadExcel} disabled={loading}>
            <FontAwesomeIcon icon={faBook} /> Generate Excel Report
          </Button>
          <Button className="me-2 generate-pdf-btn" color="primary" onClick={handleDownloadPdf} disabled={loading}>
            <FontAwesomeIcon icon={faBook} /> Generate PDF Report
          </Button>
        </div>
      </h2>
      <div className="d-flex">
        <div className="d-flex dropDownContainer">
          <span className="me-2 ms-1 mt-1 text-dark">
            <strong>Select Lender Name :</strong>
          </span>
          <select value={selectedLenderName} onChange={handleLenderName}>
            <option value="">All Lenders</option>
            {allLenderNames?.map((name, index) => (
              <option key={index} value={name}>
                {name}
              </option>
            ))}
          </select>
        </div>

        <Button onClick={handleLoadLenderList} color="primary" className="ms-3">
          View
        </Button>
      </div>
      <div className="table-responsive">
        <InfiniteScroll
          dataLength={loanFunderList?.content ? loanFunderList?.content?.length : 0}
          next={handleLoadMore}
          hasMore={!!(links && links.next && paginationState && paginationState.activePage && links.next > paginationState.activePage - 1)}
          loader={<div className="loader">Loading ...</div>}
        >
          {loading && <div className="loader">Loading ...</div>}
          {!loading && loanFunderList?.content && loanFunderList?.content?.length > 0 ? (
            <Table responsive className="table-striped table-bordered  tableContainer mt-5">
              <thead>
                <tr>
                  <th className="hand">Lender Flurish Unique ID</th>
                  <th className="hand">Lender Business Name</th>
                  <th className="hand">Loan Application Unique ID</th>
                  <th className="hand">Ledger Loan Unique ID</th>
                  <th className="hand">Total Invested</th>
                  <th className="hand">Total Principal Repaid</th>
                  <th className="hand">Total Fee Repaid</th>
                  <th>Total Outstanding</th>
                </tr>
              </thead>
              <tbody>
                {loanFunderList?.content?.map((loanFunder, i) => (
                  <tr key={`entity-${i}`} data-cy="entityTable">
                    <td>{loanFunder?.lender_flurish_unique_id}</td>
                    <td>{loanFunder?.lender_business_name}</td>
                    <td>{loanFunder?.loan_application_unique_id}</td>
                    <td>{loanFunder?.ledger_loan_account_id}</td>
                    <td>
                      <NumberFormat
                        value={loanFunder?.total_invested}
                        thousandSeparator={true}
                        prefix={'€ '}
                        displayType={'text'}
                      />
                    </td>
                    <td>
                      <NumberFormat
                        value={loanFunder?.total_principal_repaid}
                        thousandSeparator={true}
                        prefix={'€ '}
                        displayType={'text'}
                      />
                    </td>
                    <td>
                      <NumberFormat
                        value={loanFunder?.total_daily_fee_repaid}
                        thousandSeparator={true}
                        prefix={'€ '}
                        displayType={'text'}
                      />
                    </td>

                    <td>
                      <NumberFormat
                        value={loanFunder?.total_outstanding}
                        thousandSeparator={true}
                        prefix={'€ '}
                        displayType={'text'}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            !loading && <div className="alert alert-warning mt-5">No Loan Funders found</div>
          )}
        </InfiniteScroll>
      </div>
      <div className="d-flex justify-content-center mt-3">
        <Pagination>
          <Pagination.First onClick={() => handlePageClick(1)} disabled={currentPage === 1} />
          <Pagination.Prev onClick={() => handlePageClick(currentPage - 1)} disabled={currentPage === 1} />
          {Array.from({ length: totalPages }, (_, index) => (
            <Pagination.Item key={index + 1} active={index + 1 === currentPage} onClick={() => handlePageClick(index + 1)}>
              {index + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next onClick={() => handlePageClick(currentPage + 1)} disabled={currentPage === totalPages} />
          <Pagination.Last onClick={() => handlePageClick(totalPages)} disabled={currentPage === totalPages} />
        </Pagination>
      </div>
    </div>
  );
};

export default LoanFunder;
