import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Button, Table } from 'reactstrap';
import { getPaginationState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { faBook } from '@fortawesome/free-solid-svg-icons';
import { getEntities, reset } from './loan-details.reducer';
import { downloadExcel } from 'react-export-table-to-excel';
const jsPDF = require('jspdf').default;
require('jspdf-autotable');
import NumberFormat from 'react-number-format';

export const LoanDetails = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const pageLocation = useLocation();
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );
  const [sorting, setSorting] = useState(false);
  const { id: borrowerId } = useParams<{ id: string }>();
  const loanDetailsList = useAppSelector(state => state.loanDetails.entities);
  const loading = useAppSelector(state => state.loanDetails.loading);
  const links = useAppSelector(state => state.loanDetails.links);
  const updateSuccess = useAppSelector(state => state.loanDetails.updateSuccess);
  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        borrowerId,
      }),
    );
  };

  const resetAll = () => {
    dispatch(reset());
    setPaginationState({
      ...paginationState,
      activePage: 1,
    });
  };

  useEffect(() => {
    resetAll();
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      resetAll();
    }
  }, [updateSuccess]);

  useEffect(() => {
    getAllEntities();
  }, [paginationState.activePage]);

  const handleLoadMore = () => {
    if ((window as any).pageYOffset > 0) {
      setPaginationState({
        ...paginationState,
        activePage: paginationState.activePage + 1,
      });
    }
  };

  useEffect(() => {
    if (sorting) {
      getAllEntities();
      setSorting(false);
    }
  }, [sorting]);

  const handleSyncList = () => {
    getAllEntities();
  };

  const handleDownloadExcel = () => {
    const body = [];
    loanDetailsList?.map(loanDetails => {
      body.push([
        loanDetails.loan_id,
        loanDetails.loan_nofrixion_account_id,
        loanDetails.net_loan,
        loanDetails.origination_fee,
        loanDetails.pending_repayment,
        loanDetails.daily_fee_amount,
        loanDetails.loan_creation_date,
        loanDetails.expected_term,
        loanDetails.flender_id,
        loanDetails.funder_id,
      ]);
    });
    downloadExcel({
      fileName: 'Loan Details',
      sheet: borrowerId,
      tablePayload: {
        header,
        body,
      },
    });
  };
  const header = [
    'Loan Account Id',
    'No Frixion Account Id',
    'Net Loan Amount',
    'Origination Fee Amount',
    'Outstanding Repayment Amount',
    'Daily Finance Fee',
    'Loan Creation Date',
    'Expected Term',
    'Flender Id',
    'Funder Id',
  ];

  const handleDownloadPdf = () => {
    const doc = new jsPDF();

    doc.setTextColor(0, 0, 0);
    doc.setFontSize(12);
    doc.text('Loan Details', 5, 10);
    doc.text('Borrower ID :', 5, 20);
    doc.text(borrowerId, 35, 20);

    const marginLeft = 5;
    const marginRight = 5;

    doc.autoTable({
      head: [
        [
          'Loan Account Id',
          'No Frixion Account Id',
          'Net Loan Amount',
          'Origination Fee Amount',
          'Outstanding Repayment Amount',
          'Daily Finance Fee',
          'Loan Creation Date',
          'Expected Term',
          'Flender Id',
          'Funder Id',
        ],
      ],
      body: loanDetailsList?.map(loanDetails => [
        loanDetails.loan_id,
        loanDetails.loan_nofrixion_account_id,
        loanDetails.net_loan,
        loanDetails.origination_fee,
        loanDetails.pending_repayment,
        loanDetails.daily_fee_amount,
        loanDetails.loan_creation_date,
        loanDetails.expected_term,
        loanDetails.flender_id,
        loanDetails.funder_id,
      ]),
      headStyles: { fillColor: [100, 100, 100] },
      margin: { top: 35, bottom: 2, left: marginLeft, right: marginRight },
      styles: { fontSize: 7 },
    });

    doc.save('Loan Details.pdf');
  };
  return (
    <>
      <div>
        <Breadcrumb>
          <BreadcrumbItem>
            <a
              onClick={() => {
                navigate('/');
              }}
            >
              Home
            </a>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <a
              onClick={() => {
                navigate('/borrower');
              }}
            >
              Borrower
            </a>
          </BreadcrumbItem>
          <BreadcrumbItem active>Loans</BreadcrumbItem>
        </Breadcrumb>
      </div>
      <div>
        <h2 id="loan-details-heading" data-cy="LoanDetailsHeading">
          Loans
          <div className="d-flex justify-content-end">
            <Button className="me-2" color="primary" onClick={handleDownloadExcel} disabled={loading}>
              <FontAwesomeIcon icon={faBook} spin={loading} /> Generate Excel Report
            </Button>
            <Button className="me-2" color="primary" onClick={handleDownloadPdf} disabled={loading}>
              <FontAwesomeIcon icon={faBook} spin={loading} /> Generate PDF Report
            </Button>
            <Button className="me-2" color="primary" onClick={handleSyncList} disabled={loading}>
              <FontAwesomeIcon icon="sync" spin={loading} /> Refresh list
            </Button>
          </div>
        </h2>
        <h6>Borrower Id : {borrowerId}</h6>
        <div className="table-responsive">
          <InfiniteScroll
            dataLength={loanDetailsList?.data ? loanDetailsList.length?.data : 0}
            next={handleLoadMore}
            hasMore={paginationState.activePage - 1 < links.next}
            loader={<div className="loader">Loading ...</div>}
          >
            {loanDetailsList && loanDetailsList.length > 0 ? (
              <Table responsive className="table-striped">
                <thead>
                  <tr>
                    <th>Loan Account Id</th>
                    <th>No Frixion Account Id</th>
                    <th>Net Loan Amount</th>
                    <th>Origination Fee Amount</th>
                    <th>Outstanding Repayment Amount</th>
                    <th>Daily Finance Fee</th>
                    <th>Loan Creation Date</th>
                    <th>Expected Term</th>
                    <th>Flender Id</th>
                    <th>Funder Id</th>
                  </tr>
                </thead>
                <tbody>
                  {loanDetailsList?.length > 0
                    ? loanDetailsList.map((loanDetails, index) => (
                      <tr key={index} data-cy="entityTable">
                        <td>{loanDetails.loan_id}</td>
                        <td>{loanDetails.loan_nofrixion_account_id}</td>
                        <td>
                          <NumberFormat value={loanDetails.net_loan} thousandSeparator={true} prefix={'€ '} displayType={'text'} />
                        </td>
                        <td>
                          <NumberFormat
                            value={loanDetails.origination_fee}
                            thousandSeparator={true}
                            prefix={'€ '}
                            displayType={'text'}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={loanDetails.pending_repayment}
                            thousandSeparator={true}
                            prefix={'€ '}
                            displayType={'text'}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={loanDetails.daily_fee_amount}
                            thousandSeparator={true}
                            prefix={'€ '}
                            displayType={'text'}
                          />
                        </td>

                        <td>{loanDetails.loan_creation_date}</td>
                        <td>{loanDetails.expected_term}</td>
                        <td>{loanDetails.flender_id}</td>
                        <td>{loanDetails.funder_id}</td>
                      </tr>
                    ))
                    : !loading && <div className="alert alert-warning">No Loan Details found</div>}
                </tbody>
              </Table>
            ) : (
              !loading && <div className="alert alert-warning">No Loan Details found</div>
            )}
          </InfiniteScroll>
        </div>
      </div>
    </>
  );
};

export default LoanDetails;
