import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Table, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { getPaginationState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity } from './loan-details.reducer';
import InfiniteScroll from 'react-infinite-scroll-component';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { downloadExcel } from 'react-export-table-to-excel';

export const LoanDetailsDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loanDetailsList = useAppSelector(state => state.loanDetails.entities);
  const pageLocation = useLocation();
  const links = useAppSelector(state => state.loanDetails.links);
  const loading = useAppSelector(state => state.loanDetails.loading);
  const { id: borrowerId } = useParams<{ id: string }>();
  const { id } = useParams<'id'>();
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);

  useEffect(() => {
    dispatch(getEntity(id));
  }, [dispatch, id]);

  const handleLoadMore = () => {
    if (window.pageYOffset > 0) {
      setPaginationState({
        ...paginationState,
        activePage: paginationState.activePage + 1,
      });
    }
  };

  const header = [
    'Date',
    'Total POS receipt',
    'Applied to Loan',
    'Total Principal Balance',
    'Repayment Amount',
    'Daily Finance Fee',
    'Principal Repaid',
    'Closing Principal Balance',
  ];

  const handleGenerateReport = () => {
    const body = loanDetailsList.map(data => {
      const loanDetails = data;
      return [
        loanDetails.loanCreationDate,
        loanDetails.totalDailyFeeAmount,
        loanDetails.totalGrossLoanAmount,
        loanDetails.originationFeeAmount,
        loanDetails.totalDailyFeeAmount,
        loanDetails.totalGrossLoanAmount,
        '85.12',
        loanDetails.totalGrossLoanAmount,
      ];
    });

    downloadExcel({
      fileName: 'loanList',
      sheet: 'Loans',
      tablePayload: {
        header,
        body,
      },
    });
  };
  return (
    <>
      <div>
        <Breadcrumb>
          <BreadcrumbItem>
            <a onClick={() => navigate('/')}>Home</a>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <a onClick={() => navigate('/borrower')}>Borrower</a>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <a onClick={() => navigate(`/loan-details/${searchParams.get('borrowerId')}`)}>Loans</a>
          </BreadcrumbItem>
          <BreadcrumbItem active>Borrower Report</BreadcrumbItem>
        </Breadcrumb>
      </div>
      <Row>
        <div className="table-responsive">
          <InfiniteScroll
            dataLength={loanDetailsList ? loanDetailsList.length : 0}
            next={handleLoadMore}
            hasMore={paginationState.activePage - 1 < links.next}
            loader={<div className="loader">Loading ...</div>}
          >
            <div className="d-flex justify-content-end">
              <Button className="me-2" color="primary" onClick={handleGenerateReport} disabled={loading}>
                <FontAwesomeIcon icon="book" spin={loading} /> Generate Report
              </Button>
            </div>
            <h6>Loan Id : {borrowerId}</h6>
            {loanDetailsList && loanDetailsList.length > 0 ? (
              <Table responsive className="table-striped">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Total POS receipt</th>
                    <th>Applied to Loan</th>
                    <th>Total Principal Balance</th>
                    <th>Repayment Amount</th>
                    <th>Daily Finance Fee</th>
                    <th>Principal Repaid</th>
                    <th>Closing Principal Balance</th>
                  </tr>
                </thead>
                <tbody>
                  {loanDetailsList.map((loanDetails, i) => (
                    <tr key={`entity-${i}`} data-cy="entityTable">
                      <td>{loanDetails.loanCreationDate}</td>
                      <td>{loanDetails.totalDailyFeeAmount}</td>
                      <td>{loanDetails.totalGrossLoanAmount}</td>
                      <td>{loanDetails.originationFeeAmount}</td>
                      <td>{loanDetails.totalDailyFeeAmount}</td>
                      <td>{loanDetails.totalGrossLoanAmount}</td>
                      <td>85.12</td>
                      <td>{loanDetails.totalGrossLoanAmount}</td>
                    </tr>
                  ))}

                  {!loading && loanDetailsList.length === 0 && (
                    <div className="alert alert-warning">No Loan Details found</div>
                  )}
                </tbody>
              </Table>
            ) : (
              !loading && <div className="alert alert-warning">No Loan Details found</div>
            )}
          </InfiniteScroll>
        </div>
      </Row>
    </>
  );
};

export default LoanDetailsDetail;
