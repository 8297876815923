import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import LoanFunder from './loan-funder';
import LoanFunderDetail from './loan-funder-detail';
import LoanFunderUpdate from './loan-funder-update';
import LoanFunderDeleteDialog from './loan-funder-delete-dialog';

const LoanFunderRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<LoanFunder />} />
    <Route path="new" element={<LoanFunderUpdate />} />
    <Route path=":id">
      <Route index element={<LoanFunderDetail />} />
      <Route path="edit" element={<LoanFunderUpdate />} />
      <Route path="delete" element={<LoanFunderDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default LoanFunderRoutes;
