import React, { useState } from 'react';
import { ValidatedField, ValidatedForm } from 'react-jhipster';
import { Col, Row, Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { createEntity } from './journal.reducer';
import { useAppDispatch } from 'app/config/store';

const Journal = () => {
    const dispatch = useAppDispatch();

    const { register, control } = useForm();
    const [accNum, setAccNum] = useState(Number);
    const [amount, setAmount] = useState(Number);
    const [transactionType, setTransactionType] = useState('');
    const [response, setResponse] = useState('');
    const [showModal, setShowModal] = useState(false);
    const handleSubmit = async values => {
        const entity = {
            accountNumber: accNum,
            amount,
            debitCredit: transactionType,
        };
        const result = await dispatch(createEntity(entity));
        try {
            if (result.payload !== undefined) {
                setResponse('Added Successfully');
            } else {
                setResponse('Transaction Adding Failed');
            }
        } catch (error) {
            setResponse("something wrong")
        }
        setShowModal(true);
    };
    const handleOk = () => {
        setShowModal(false);
    };
    return (
        <div>
            <h2>Journal</h2>

            <ValidatedForm control={control} onSubmit={handleSubmit}>
                <Row>
                    <Col>
                        <ValidatedField
                            label="Account Number"
                            name="accountNumber"
                            id="accountNumber"
                            type="number"
                            placeholder="Account Number"
                            register={register}
                            validate={{
                                required: { value: true, message: 'This field is required.' },
                            }}
                            onChange={e => setAccNum(e.target.valueAsNumber)}
                        />
                    </Col>
                    <Col>
                        <ValidatedField
                            label="Amount"
                            name="amount"
                            id="amount"
                            type="number"
                            placeholder="Amount"
                            register={register}
                            validate={{
                                required: { value: true, message: 'This field is required.' },
                            }}
                            onChange={e => setAmount(e.target.valueAsNumber)}
                        />
                    </Col>
                    <Col>
                        <ValidatedField
                            label="Transaction Type"
                            name="transactionType"
                            id="transactionType"
                            type="select"
                            placeholder="Transaction Type"
                            register={register}
                            validate={{
                                required: { value: true, message: 'This field is required.' },
                            }}
                            onChange={e => setTransactionType(e.target.value)}
                        >
                            <option value="Debit">Debit</option>
                            <option value="Credit">Credit</option>
                        </ValidatedField>
                    </Col>
                </Row>
                <div className="d-flex flex-row-reverse">
                    <Button type="submit" color="primary" onClick={handleSubmit}>
                        Add Transaction
                    </Button>
                </div>
            </ValidatedForm>

            <div>
                <Modal isOpen={showModal}>
                    <ModalBody>
                        <p>{response}</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={handleOk}>
                            OK
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        </div>
    );
};
export default Journal;
