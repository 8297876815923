/* eslint-disable no-new */
import './home.scss';
import React, { useCallback } from 'react';

import { Row, Col, Button } from 'reactstrap';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement } from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';
import { useAppDispatch } from 'app/config/store';
import { fetchUrl } from './home.reducer';
export const Home = () => {
  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Annual Overview Bar Chart',
      },
    },
  };
  const optionsLine = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Annual Overview Line Chart',
      },
    },
  };

  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const data = {
    labels,
    datasets: [
      {
        label: 'Lenders',
        data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        backgroundColor: '#428ECF',
      },
      {
        label: 'Borrowers',
        data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        backgroundColor: '#BBF539',
      },
    ],
  };

  const dispatch = useAppDispatch();

  const handleButtonClick = useCallback(async () => {
    try {
      const action = await dispatch(fetchUrl());
      const response = action.payload;
      window.open(response);
    } catch (error) {
      console.error('Error fetching URL:', error);
    }
  }, [dispatch]);

  return (
    <Row>
      <Col md="3">
        <div className="card">
          <span className="rounded hipster" />
          <span className="rounded hipsterBg" />
          <span className="rounded hipster" />
        </div>
      </Col>
      <Col md="9">
        <div className="d-grid gap-2">
          <div>
            <div className='d-flex justify-content-end'>
              <Button color='primary' className='mt-2 mb-5' onClick={handleButtonClick}>  NF USER TOKEN LOGIN / UPDATE</Button></div>
          </div>
          <div className="card ">
            <h3 className='mt-3'>Dashboard</h3>
            <Row>
              <Col md="6" className="pad mt-5">
                <div className="chart  mt-5">
                  <Bar options={options} data={data} />
                </div>
              </Col>
              <Col md="6" className="pad  mt-5">
                <div className="chart  mt-5">
                  <Line options={optionsLine} data={data} />
                </div>
              </Col>
            </Row>
          </div>
        </div>

      </Col>
    </Row>
  );
};

export default Home;
