import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity, updateEntity, createEntity } from './funder-loan-contribution.reducer';

export const FunderLoanContributionUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const funderLoanContributionEntity = useAppSelector(state => state.funderLoanContribution.entity);
  const loading = useAppSelector(state => state.funderLoanContribution.loading);
  const updating = useAppSelector(state => state.funderLoanContribution.updating);
  const updateSuccess = useAppSelector(state => state.funderLoanContribution.updateSuccess);

  const handleClose = () => {
    navigate('/funder-loan-contribution');
  };

  useEffect(() => {
    if (!isNew) {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    if (values.fundingAmount !== undefined && typeof values.fundingAmount !== 'number') {
      values.fundingAmount = Number(values.fundingAmount);
    }
    if (values.funderPrincipalSplitPercent !== undefined && typeof values.funderPrincipalSplitPercent !== 'number') {
      values.funderPrincipalSplitPercent = Number(values.funderPrincipalSplitPercent);
    }
    if (values.dailyFinanceFeeAmount !== undefined && typeof values.dailyFinanceFeeAmount !== 'number') {
      values.dailyFinanceFeeAmount = Number(values.dailyFinanceFeeAmount);
    }

    const entity = {
      ...funderLoanContributionEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
        ...funderLoanContributionEntity,
      };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="flenderApp.funderLoanContribution.home.createOrEditLabel" data-cy="FunderLoanContributionCreateUpdateHeading">
            Create or edit a Funder Loan Contribution
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
              <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
                {!isNew && (
                  <ValidatedField
                    name="id"
                    required
                    readOnly
                    id="funder-loan-contribution-id"
                    label="Id"
                    validate={{ required: true }}
                  />
                )}
              <ValidatedField
                label="Lender Unique Id"
                id="funder-loan-contribution-lenderUniqueId"
                name="lenderUniqueId"
                data-cy="lenderUniqueId"
                type="text"
              />
              <ValidatedField
                label="Funding Amount"
                id="funder-loan-contribution-fundingAmount"
                name="fundingAmount"
                data-cy="fundingAmount"
                type="text"
              />
              <ValidatedField
                label="Funder Principal Split Percent"
                id="funder-loan-contribution-funderPrincipalSplitPercent"
                name="funderPrincipalSplitPercent"
                data-cy="funderPrincipalSplitPercent"
                type="text"
              />
              <ValidatedField
                label="Daily Finance Fee Amount"
                id="funder-loan-contribution-dailyFinanceFeeAmount"
                name="dailyFinanceFeeAmount"
                data-cy="dailyFinanceFeeAmount"
                type="text"
              />
              <ValidatedField
                label="Loan Application Unique Id"
                id="funder-loan-contribution-loanApplicationUniqueId"
                name="loanApplicationUniqueId"
                data-cy="loanApplicationUniqueId"
                type="text"
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/funder-loan-contribution" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default FunderLoanContributionUpdate;
