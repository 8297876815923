import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';

interface GLAccount {
  account_number: string;
  account_name: string;
  unique_id: string;
  account_type: string;
  status: string;
  currency: string;
  account_balance: number;
  entity_type: string;
  real_bank_iban: string;
}

const initialState: EntityState<GLAccount> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: {
    account_number: '',
    account_name: '',
    unique_id: '',
    account_type: '',
    status: '',
    currency: '',
    account_balance: 0,
    entity_type: '',
    real_bank_iban: '',
  },
  links: { next: 0 },
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = `${SERVER_API_URL}core-banking/flender/gl-accounts/create`;

export const createEntity = createAsyncThunk(
  'glAccount/create_entity',
  async (entity: any, thunkAPI) => {
    const result = await axios.post<GLAccount>(apiUrl, cleanEntity(entity));
    return result.data;
  },
  { serializeError: serializeAxiosError },
);

export const glAccountSlice = createEntitySlice({
  name: 'glAccount',
  initialState,
  extraReducers(builder) {
    builder
      .addMatcher(isFulfilled(createEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload;
      })
      .addMatcher(isPending(createEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = glAccountSlice.actions;

export default glAccountSlice.reducer;
