import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Borrower from './borrower';
import BorrowerDetail from './borrower-detail';
import BorrowerUpdate from './borrower-update';
import BorrowerDeleteDialog from './borrower-delete-dialog';

const BorrowerRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Borrower />} />
    <Route path="new" element={<BorrowerUpdate />} />
    <Route path=":id">
      <Route index element={<BorrowerDetail />} />
      <Route path="edit" element={<BorrowerUpdate />} />
      <Route path="delete" element={<BorrowerDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default BorrowerRoutes;
