import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { loadMoreDataWhenScrolled, parseHeaderForLinks } from 'react-jhipster';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IBorrower, defaultValue } from 'app/shared/model/borrower.model';

const initialState: EntityState<IBorrower> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  links: { next: 0 },
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl1 = `${SERVER_API_URL}core-banking/flender/borrower/all`;
const apiUrl2 = `${SERVER_API_URL}core-banking/flender/borrower/get-all-borrowers`;

// Actions

export const getEntities = createAsyncThunk('borrower/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl1}`;
  return axios.get<IBorrower[]>(requestUrl);
});

export const getBorrowerDetails = createAsyncThunk(
  'borrower/fetch_borrower_details',
  async ({ count, index, searchKeyword }: IQueryParams) => {
    const requestUrl = `${apiUrl2}?count=${count}&index=${index}&searchKeyword=${searchKeyword}`;
    return axios.get<IBorrower[]>(requestUrl);
  },
);

export const getEntity = createAsyncThunk(
  'borrower/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl1}/${id}`;
    return axios.get<IBorrower>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const createEntity = createAsyncThunk(
  'borrower/create_entity',
  async (entity: IBorrower, thunkAPI) => {
    return axios.post<IBorrower>(apiUrl1, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError },
);

export const updateEntity = createAsyncThunk(
  'borrower/update_entity',
  async (entity: IBorrower, thunkAPI) => {
    return axios.put<IBorrower>(`${apiUrl1}/${entity.id}`, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError },
);

export const partialUpdateEntity = createAsyncThunk(
  'borrower/partial_update_entity',
  async (entity: IBorrower, thunkAPI) => {
    return axios.patch<IBorrower>(`${apiUrl1}/${entity.id}`, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError },
);

export const deleteEntity = createAsyncThunk(
  'borrower/delete_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl1}/${id}`;
    return axios.delete<IBorrower>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);
// slice

export const BorrowerSlice = createEntitySlice({
  name: 'borrower',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addCase(getBorrowerDetails.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(getBorrowerDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.borrowerDetails = action.payload;
      })
      .addCase(getBorrowerDetails.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      })

      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;
        const links = headers && headers.link ? parseHeaderForLinks(headers.link) : '';

        return {
          ...state,
          loading: false,
          links,
          entities: loadMoreDataWhenScrolled(state.entities, data, links),
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })

      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = BorrowerSlice.actions;

// Reducer
export default BorrowerSlice.reducer;
