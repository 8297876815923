import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import {LoanRepayment} from './loan-repayment';
import LoanRepaymentDetail from './loan-repayment-detail';
import LoanRepaymentUpdate from './loan-repayment-update';
import LoanRepaymentDeleteDialog from './loan-repayment-delete-dialog';

const LoanRepaymentRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<LoanRepayment />} />
    <Route path="new" element={<LoanRepaymentUpdate />} />
    <Route path=":id">
      <Route index element={<LoanRepaymentDetail />} />
      <Route path="edit" element={<LoanRepaymentUpdate />} />
      <Route path="delete" element={<LoanRepaymentDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default LoanRepaymentRoutes;
