import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './general-ledger.reducer';

export const GeneralLedgerDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const generalLedgerEntity = useAppSelector(state => state.generalLedger.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="generalLedgerDetailsHeading">General Ledger</h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">Id</span>
          </dt>
          <dd>{generalLedgerEntity.id}</dd>
          <dt>
            <span id="credit">Credit</span>
          </dt>
          <dd>{generalLedgerEntity.credit}</dd>
          <dt>
            <span id="debit">Debit</span>
          </dt>
          <dd>{generalLedgerEntity.debit}</dd>
          <dt>
            <span id="currency">Currency</span>
          </dt>
          <dd>{generalLedgerEntity.currency}</dd>
          <dt>
            <span id="transactionReferenceNumber">Transaction Reference Number</span>
          </dt>
          <dd>{generalLedgerEntity.transactionReferenceNumber}</dd>
          <dt>
            <span id="transactionDate">Transaction Date</span>
          </dt>
          <dd>
            {generalLedgerEntity.transactionDate ? (
              <TextFormat value={generalLedgerEntity.transactionDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="narration">Narration</span>
          </dt>
          <dd>{generalLedgerEntity.narration}</dd>
          <dt>
            <span id="loanId">Loan Id</span>
          </dt>
          <dd>{generalLedgerEntity.loanId}</dd>
          <dt>
            <span id="accountNumber">Account Number</span>
          </dt>
          <dd>{generalLedgerEntity.accountNumber}</dd>
          <dt>
            <span id="debitCreditFlag">Debit Credit Flag</span>
          </dt>
          <dd>{generalLedgerEntity.debitCreditFlag}</dd>
          <dt>
            <span id="postingDate">Posting Date</span>
          </dt>
          <dd>
            {generalLedgerEntity.postingDate ? (
              <TextFormat value={generalLedgerEntity.postingDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="entityId">Entity Id</span>
          </dt>
          <dd>{generalLedgerEntity.entityId}</dd>
          <dt>
            <span id="entityType">Entity Type</span>
          </dt>
          <dd>{generalLedgerEntity.entityType}</dd>
        </dl>
        <Button tag={Link} to="/general-ledger" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/general-ledger/${generalLedgerEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
        </Button>
      </Col>
    </Row>
  );
};

export default GeneralLedgerDetail;
