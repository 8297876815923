import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import LoanDetails from './loan-details';
import LoanDetailsDetail from './loan-details-detail';
import LoanDetailsUpdate from './loan-details-update';
import LoanDetailsDeleteDialog from './loan-details-delete-dialog';
import LoanDetailsReport from './loan-details-detail-report';

const LoanDetailsRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<LoanDetails />} />
    <Route path="new" element={<LoanDetailsUpdate />} />
    <Route path="report" element={<LoanDetailsReport />} />

    <Route path=":id">
      <Route index element={<LoanDetails />} />
      <Route path="edit" element={<LoanDetailsUpdate />} />
      <Route path="details" element={<LoanDetailsDetail />} />
      <Route path="delete" element={<LoanDetailsDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default LoanDetailsRoutes;
