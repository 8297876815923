import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import GeneralLedger from './general-ledger';
import GeneralLedgerDetail from './general-ledger-detail';
import GeneralLedgerUpdate from './general-ledger-update';
import GeneralLedgerDeleteDialog from './general-ledger-delete-dialog';

const GeneralLedgerRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<GeneralLedger />} />
    <Route path="new" element={<GeneralLedgerUpdate />} />
    <Route path=":id">
      <Route index element={<GeneralLedgerDetail />} />
      <Route path="edit" element={<GeneralLedgerUpdate />} />
      <Route path="delete" element={<GeneralLedgerDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default GeneralLedgerRoutes;
