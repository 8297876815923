import React, { useState } from 'react';

import { NavItem, NavLink } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink as Link } from 'react-router-dom';

const NavDropdown = props => {
  const [showMenu1, setShowMenu1] = useState(false);

  const handleArrowClick = menuName => {
    if (menuName == 'Agent') {
      setShowMenu1(!showMenu1);
    }
  };

  return (
    <NavItem className={`${showMenu1 ? 'showMenu' : ''}`}>
      <div className="iocn-link" onClick={() => handleArrowClick('Agent')}>
        <NavLink tag={Link} to="/">
          <FontAwesomeIcon className="sideBarCustomColor" icon={props.icon} />
          <span className="sideBarCustomColor">{props.name}</span>
        </NavLink>
        <i className={`bx bxs-chevron-down arrow ${showMenu1 ? 'showMenu' : ''}`}></i>
      </div>
      <ul className="sub-menu">
        <li>
          <a className="sideBarCustomColor" href="#">
            {props.name}
          </a>
        </li>
        {props.children}
      </ul>
    </NavItem>
  );
};

export default NavDropdown;
