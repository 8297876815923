import React from 'react';
import MenuItem from 'app/shared/layout/menus/menu-item';

import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const accountMenuItems = () => (
  <>
    <MenuItem id="login-item" icon="sign-in-alt" to="/login" data-cy="login">
      Sign in
    </MenuItem>
    <MenuItem icon="user-plus" to="/account/register" data-cy="register">
      Register
    </MenuItem>
  </>
);

export const AccountMenu = ({ isAuthenticated = false, isVisible = true }) => (
  <div>
    <NavItem>
      <NavLink tag={Link} to="/logout" className="d-flex align-items-center" title="Logout" >
        <FontAwesomeIcon icon="sign-out-alt" className="sideBarCustomColor" />
        {isVisible && isAuthenticated && <span className="sideBarCustomColor"> Sign out</span>}
      </NavLink>
    </NavItem>
  </div>
);


export default AccountMenu;
