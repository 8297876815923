import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity, updateEntity, createEntity } from './borrower.reducer';

export const BorrowerUpdate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const borrowerEntity = useAppSelector(state => state.borrower.entity);
  const loading = useAppSelector(state => state.borrower.loading);
  const updating = useAppSelector(state => state.borrower.updating);
  const updateSuccess = useAppSelector(state => state.borrower.updateSuccess);

  const handleClose = () => {
    navigate('/borrower');
  };

  useEffect(() => {
    if (!isNew) {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    if (values.borrowerRealBankIban !== undefined && typeof values.borrowerRealBankIban !== 'number') {
      values.borrowerRealBankIban = Number(values.borrowerRealBankIban);
    }
    const entity = {
      ...borrowerEntity,
      ...values,
    };
    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () => {
    return isNew ? {} : { ...borrowerEntity };
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="flenderApp.borrower.home.createOrEditLabel" data-cy="BorrowerCreateUpdateHeading">
            Create or edit a Borrower
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew && (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="borrower-id"
                  label="Id"
                  validate={{ required: true }}
                />
              )}
              <ValidatedField
                label="Borrower Unique Id"
                id="borrower-borrowerUniqueId"
                name="borrowerUniqueId"
                data-cy="borrowerUniqueId"
                type="text"
              />
              <ValidatedField
                label="Borrower Business Name"
                id="borrower-borrowerBusinessName"
                name="borrowerBusinessName"
                data-cy="borrowerBusinessName"
                type="text"
              />
              <ValidatedField
                label="Borrower Business Cro Number"
                id="borrower-borrowerBusinessCroNumber"
                name="borrowerBusinessCroNumber"
                data-cy="borrowerBusinessCroNumber"
                type="text"
              />
              <ValidatedField
                label="Borrower Business Email"
                id="borrower-borrowerBusinessEmail"
                name="borrowerBusinessEmail"
                data-cy="borrowerBusinessEmail"
                type="text"
              />
              <ValidatedField
                label="Borrower Business Represent Person Name"
                id="borrower-borrowerBusinessRepresentPersonName"
                name="borrowerBusinessRepresentPersonName"
                data-cy="borrowerBusinessRepresentPersonName"
                type="text"
              />
              <ValidatedField
                label="Borrower Fire Account Ican"
                id="borrower-borrowerRealBankIban"
                name="borrowerRealBankIban"
                data-cy="borrowerRealBankIban"
                type="text"
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/borrower" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default BorrowerUpdate;
