import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity, updateEntity, createEntity } from './general-ledger.reducer';

export const GeneralLedgerUpdate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const isNew = id === undefined;
  const generalLedgerEntity = useAppSelector(state => state.generalLedger.entity);
  const loading = useAppSelector(state => state.generalLedger.loading);
  const updating = useAppSelector(state => state.generalLedger.updating);
  const updateSuccess = useAppSelector(state => state.generalLedger.updateSuccess);

  const handleClose = () => {
    navigate('/general-ledger');
  };

  useEffect(() => {
    if (!isNew) {
      dispatch(getEntity(id));
    }
  }, [dispatch, id, isNew]);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    if (values.credit !== undefined && typeof values.credit !== 'number') {
      values.credit = Number(values.credit);
    }
    if (values.debit !== undefined && typeof values.debit !== 'number') {
      values.debit = Number(values.debit);
    }
    values.transactionDate = convertDateTimeToServer(values.transactionDate);
    values.postingDate = convertDateTimeToServer(values.postingDate);

    const entity = {
      ...generalLedgerEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () => {
    if (isNew) {
      return {
        transactionDate: displayDefaultDateTime(),
        postingDate: displayDefaultDateTime(),
      };
    } else {
      return {
        ...generalLedgerEntity,
        transactionDate: convertDateTimeFromServer(generalLedgerEntity.transactionDate),
        postingDate: convertDateTimeFromServer(generalLedgerEntity.postingDate),
      };
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="flenderApp.generalLedger.home.createOrEditLabel" data-cy="GeneralLedgerCreateUpdateHeading">
            Create or edit a General Ledger
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew && (
                <ValidatedField name="id" required readOnly id="general-ledger-id" label="Id" validate={{ required: true }} />
              )}
              <ValidatedField label="Credit" id="general-ledger-credit" name="credit" data-cy="credit" type="text" />
              <ValidatedField label="Debit" id="general-ledger-debit" name="debit" data-cy="debit" type="text" />
              <ValidatedField label="Currency" id="general-ledger-currency" name="currency" data-cy="currency" type="text" />
              <ValidatedField
                label="Transaction Reference Number"
                id="general-ledger-transactionReferenceNumber"
                name="transactionReferenceNumber"
                data-cy="transactionReferenceNumber"
                type="text"
              />
              <ValidatedField
                label="Transaction Date"
                id="general-ledger-transactionDate"
                name="transactionDate"
                data-cy="transactionDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField label="Narration" id="general-ledger-narration" name="narration" data-cy="narration" type="text" />
              <ValidatedField label="Loan Id" id="general-ledger-loanId" name="loanId" data-cy="loanId" type="text" />
              <ValidatedField
                label="Account Number"
                id="general-ledger-accountNumber"
                name="accountNumber"
                data-cy="accountNumber"
                type="text"
              />
              <ValidatedField
                label="Debit Credit Flag"
                id="general-ledger-debitCreditFlag"
                name="debitCreditFlag"
                data-cy="debitCreditFlag"
                type="text"
              />
              <ValidatedField
                label="Posting Date"
                id="general-ledger-postingDate"
                name="postingDate"
                data-cy="postingDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField label="Entity Id" id="general-ledger-entityId" name="entityId" data-cy="entityId" type="text" />
              <ValidatedField label="Entity Type" id="general-ledger-entityType" name="entityType" data-cy="entityType" type="text" />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/general-ledger" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default GeneralLedgerUpdate;
