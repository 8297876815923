// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ==========================================================================
Main page styles
========================================================================== */
.hipster {
  display: inline-block;
  width: 100%;
  height: 228px;
}

.hipsterBg {
  display: inline-block;
  width: 100%;
  height: 60px;
  background: url("../../../content/images/logo-jhipster.png") no-repeat center top;
  background-size: contain;
  background-color: "#428ECF";
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/modules/home/home.scss"],"names":[],"mappings":"AAAA;;4EAAA;AAGA;EACE,qBAAA;EACA,WAAA;EACA,aAAA;AACF;;AACA;EACE,qBAAA;EACA,WAAA;EACA,YAAA;EACA,iFAAA;EACA,wBAAA;EACA,2BAAA;AAEF","sourcesContent":["/* ==========================================================================\nMain page styles\n========================================================================== */\n.hipster {\n  display: inline-block;\n  width: 100%;\n  height: 228px;\n}\n.hipsterBg {\n  display: inline-block;\n  width: 100%;\n  height: 60px;\n  background: url('../../../content/images/logo-jhipster.png') no-repeat center top;\n  background-size: contain;\n  background-color: '#428ECF';\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
