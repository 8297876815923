import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, Breadcrumb, BreadcrumbItem, Card } from 'reactstrap';
import { ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity, updateEntity, createEntity } from './loan-details.reducer';

export const LoanDetailsUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;
  const loanDetailsEntity = useAppSelector(state => state.loanDetails.entity);

  const loading = useAppSelector(state => state.loanDetails.loading);
  const updating = useAppSelector(state => state.loanDetails.updating);
  const updateSuccess = useAppSelector(state => state.loanDetails.updateSuccess);

  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);

  const handleClose = () => {
    navigate('/loan-details');
  };

  useEffect(() => {
    if (!isNew) {
      dispatch(getEntity(id));
    }
  }, []);


  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    if (values.netLoanAmount !== undefined && typeof values.netLoanAmount !== 'number') {
      values.netLoanAmount = Number(values.netLoanAmount);
    }
    if (values.totalGrossLoanAmount !== undefined && typeof values.totalGrossLoanAmount !== 'number') {
      values.totalGrossLoanAmount = Number(values.totalGrossLoanAmount);
    }
    if (values.originationFeeAmount !== undefined && typeof values.originationFeeAmount !== 'number') {
      values.originationFeeAmount = Number(values.originationFeeAmount);
    }
    if (values.totalDailyFeeAmount !== undefined && typeof values.totalDailyFeeAmount !== 'number') {
      values.totalDailyFeeAmount = Number(values.totalDailyFeeAmount);
    }
    if (values.dailyHoldbackPercent !== undefined && typeof values.dailyHoldbackPercent !== 'number') {
      values.dailyHoldbackPercent = Number(values.dailyHoldbackPercent);
    }
    if (values.expectedTerm !== undefined && typeof values.expectedTerm !== 'number') {
      values.expectedTerm = Number(values.expectedTerm);
    }
    if (values.expectedDailyPosReceipts !== undefined && typeof values.expectedDailyPosReceipts !== 'number') {
      values.expectedDailyPosReceipts = Number(values.expectedDailyPosReceipts);
    }
    if (values.expectedDailyHoldback !== undefined && typeof values.expectedDailyHoldback !== 'number') {
      values.expectedDailyHoldback = Number(values.expectedDailyHoldback);
    }

    const entity = {
      ...loanDetailsEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
        ...loanDetailsEntity,
      };

  return (
    <>
      <div>
        <Breadcrumb>
          <BreadcrumbItem>
            <a
              onClick={() => {
                navigate('/');
              }}
            >
              Home
            </a>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <a
              onClick={() => {
                navigate('/borrower');
              }}
            >
              Borrower
            </a>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <a
              onClick={() => {
                navigate(`/loan-details/${searchParams.get('borrowerId')}`);
              }}
            >
              Loans
            </a>
          </BreadcrumbItem>
          <BreadcrumbItem active>Edit</BreadcrumbItem>
        </Breadcrumb>
      </div>
      <Card>
        <div>
          <Row className="justify-content-center">
            <h2
              style={{ textAlign: 'center', alignSelf: 'center', marginBottom: 40 }}
              id="flenderApp.loanDetails.home.createOrEditLabel"
              data-cy="LoanDetailsCreateUpdateHeading"
            >
              Edit Loan Details
            </h2>

            <Col md="10">
              {loading ? (
                <p>Loading...</p>
              ) : (
                <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
                  <Row>
                    <Col md="6">

                      <ValidatedField
                        label="Loan Application Unique Id"
                        id="loan-details-loanApplicationUniqueId"
                        readOnly
                        name="loanApplicationUniqueId"
                        data-cy="loanApplicationUniqueId"
                        type="text"
                      />
                      <ValidatedField
                        label="Net Loan Amount"
                        id="loan-details-netLoanAmount"
                        name="netLoanAmount"
                        data-cy="netLoanAmount"
                        type="text"
                      />
                      <ValidatedField
                        label="Total Gross Loan Amount"
                        id="loan-details-totalGrossLoanAmount"
                        name="totalGrossLoanAmount"
                        data-cy="totalGrossLoanAmount"
                        type="text"
                      />
                      <ValidatedField
                        label="Origination Fee Amount"
                        id="loan-details-originationFeeAmount"
                        name="originationFeeAmount"
                        data-cy="originationFeeAmount"
                        type="text"
                      />
                      <ValidatedField
                        label="Total Daily Fee Amount"
                        id="loan-details-totalDailyFeeAmount"
                        name="totalDailyFeeAmount"
                        data-cy="totalDailyFeeAmount"
                        type="text"
                      />
                      <ValidatedField
                        label="Daily Holdback Percent"
                        id="loan-details-dailyHoldbackPercent"
                        name="dailyHoldbackPercent"
                        data-cy="dailyHoldbackPercent"
                        type="text"
                      />
                    </Col>
                    <Col md="6">
                      <ValidatedField
                        label="Loan Creation Date"
                        id="loan-details-loanCreationDate"
                        name="loanCreationDate"
                        data-cy="loanCreationDate"
                        type="date"
                      />
                      <ValidatedField
                        label="Expected Term"
                        id="loan-details-expectedTerm"
                        name="expectedTerm"
                        data-cy="expectedTerm"
                        type="text"
                      />
                      <ValidatedField
                        label="Expected Daily Pos Receipts"
                        id="loan-details-expectedDailyPosReceipts"
                        name="expectedDailyPosReceipts"
                        data-cy="expectedDailyPosReceipts"
                        type="text"
                      />
                      <ValidatedField
                        label="Expected Daily Holdback"
                        id="loan-details-expectedDailyHoldback"
                        name="expectedDailyHoldback"
                        data-cy="expectedDailyHoldback"
                        type="text"
                      />
                      <ValidatedField
                        label="Flender Id"
                        id="loan-details-flenderId"
                        readOnly
                        name="flenderId"
                        data-cy="flenderId"
                        type="text"
                      />
                      <ValidatedField
                        label="Funder Id"
                        id="loan-details-funderId"
                        readOnly
                        name="funderId"
                        data-cy="funderId"
                        type="text"
                      />
                      <ValidatedField
                        label="Borrower Id"
                        id="loan-details-borrowerId"
                        readOnly
                        name="borrowerId"
                        data-cy="borrowerId"
                        type="text"
                      />
                    </Col>
                  </Row>
                  <div className="d-flex flex-row-reverse">
                    <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                      <FontAwesomeIcon icon="save" />
                      &nbsp; Save
                    </Button>
                    <Button
                      tag={Link}
                      id="cancel-save"
                      data-cy="entityCreateCancelButton"
                      to={`/loan-details/${searchParams.get('borrowerId')}`}
                      replace
                      color="info"
                    >
                      <FontAwesomeIcon icon="arrow-left" />
                      &nbsp;
                      <span className="d-none d-md-inline">Back</span>
                    </Button>
                    &nbsp;
                  </div>
                </ValidatedForm>
              )}
            </Col>
          </Row>
        </div>
      </Card>
    </>
  );
};

export default LoanDetailsUpdate;
