import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLocation } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { getPaginationState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook } from '@fortawesome/free-solid-svg-icons';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppSelector } from 'app/config/store';


export const Approval = () => {

  const pageLocation = useLocation();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );
  const approvalList = [
    {
      id: 1,
      credit: 1000,
      debit: ' Sam',
      currency: '9ebc1f178',
      txnRefNumber: '55000',
      txnDate: '8bc1f178',
      narration2: '35000',
      loanId: '975ebc8',
      accountNumber: '6000',
      debitCreditFlag: '2024-03-13T08:00:00Z',
      postingDate: '2024-03-13T08:00:00Z',
      entityId: 'Entity001',
      entityType: 'TypeA',
    },
    {
      id: 2,
      credit: 4000,
      debit: 'James',
      currency: '8bc1f178',
      txnRefNumber: '85000',
      txnDate: '8bc1f178',
      narration2: '95000',
      loanId: '455ebc8',
      accountNumber: '5400',
      debitCreditFlag: '2024-03-13T08:00:00Z',
      postingDate: '2024-03-13T08:00:00Z',
      entityId: 'Entity001',
      entityType: 'TypeA',
    },
  ];

  const loading = useAppSelector(state => state?.generalLedger?.loading);
  const links = useAppSelector(state => state?.generalLedger?.links);



  const [selectedOrders, setSelectedOrders] = useState([]);

  const handleCheckboxChange = orderNumber => {
    if (selectedOrders.includes(orderNumber)) {
      setSelectedOrders(selectedOrders.filter(order => order !== orderNumber));
    } else {
      setSelectedOrders([...selectedOrders, orderNumber]);
    }
  };



  const handleLoadMore = () => {
    if (window.pageYOffset > 0) {
      setPaginationState({
        ...paginationState,
        activePage: paginationState.activePage + 1,
      });
    }
  };
  return (
    <div>
      <h2 id="general-ledger-heading" data-cy="GeneralLedgerHeading">
        Payout Approval
      </h2>
      <div className="d-flex justify-content-end">

        <Button className="me-2" color="primary" disabled={loading}>
          Approve
        </Button>
        <Button className="me-2" color="primary" disabled={loading}>
          <FontAwesomeIcon icon={faBook} spin={loading} /> Generate Report
        </Button>
      </div>
      <div className="table-responsive mt-5">
        <InfiniteScroll
          dataLength={approvalList ? approvalList.length : 0}
          next={handleLoadMore}
          hasMore={paginationState.activePage - 1 < links?.next}
          loader={<div className="loader">Loading ...</div>}
        >
          {approvalList && approvalList.length > 0 ? (
            <Table responsive className="table-striped table-bordered">
              <thead>
                <tr>
                  <th className="hand">
                    Select
                  </th>
                  <th className="hand">
                    Ledger Loan A/C
                  </th>
                  <th className="hand">
                    Borrower Name
                  </th>
                  <th className="hand">
                    Borrower nofrixtion id
                  </th>
                  <th className="hand">
                    Borrower Payout
                  </th>
                  <th className="hand">
                    Funder nofrixion A/c
                  </th>
                  <th className="hand">
                    Funder payout amount
                  </th>
                  <th className="hand">
                    Flender nofrixion A/c
                  </th>
                  <th className="hand">
                    Flender payout amount
                  </th>
                  <th className="hand">
                    Approval Date timestamp
                  </th>
                </tr>
              </thead>
              <tbody>
                {approvalList &&
                  approvalList.map((generalLedger, i) => (
                    <tr key={`entity-${i}`} data-cy="entityTable">
                      <td className="text-center">
                        <div className="form-check">

                          <input
                            type="checkbox"
                            className="form-check-input"
                            checked={selectedOrders.includes(generalLedger.id)}
                            onChange={() => handleCheckboxChange(generalLedger.id)}
                          />
                        </div>
                      </td>
                      <td>{generalLedger.credit}</td>
                      <td>{generalLedger.debit}</td>
                      <td>{generalLedger.currency}</td>
                      <td>{generalLedger.txnRefNumber}</td>
                      <td>
                        <td>{generalLedger.txnDate}</td>

                      </td>
                      <td>{generalLedger.narration2}</td>
                      <td>{generalLedger.loanId}</td>
                      <td>{generalLedger.accountNumber}</td>
                      <td>{generalLedger.debitCreditFlag}</td>

                    </tr>
                  ))}
              </tbody>
            </Table>
          ) : (
            !loading && (
              <div className=" ">
                <h3 className="text-dark">No Data found </h3>
              </div>
            )
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default Approval;
