import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities as getLoans } from 'app/entities/loan/loan.reducer';
import { getEntity, updateEntity, createEntity  } from './loan-funder.reducer';

export const LoanFunderUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const loans = useAppSelector(state => state.loan.entities);
  const loanFunderEntity = useAppSelector(state => state.loanFunder.entity);
  const loading = useAppSelector(state => state.loanFunder.loading);
  const updating = useAppSelector(state => state.loanFunder.updating);
  const updateSuccess = useAppSelector(state => state.loanFunder.updateSuccess);

  const handleClose = () => {
    navigate('/loan-funder');
  };

  useEffect(() => {
    if (!isNew) {
      dispatch(getEntity(id));
    }

    dispatch(getLoans());
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    if (values.fundingPercentage !== undefined && typeof values.fundingPercentage !== 'number') {
      values.fundingPercentage = Number(values.fundingPercentage);
    }
    if (values.fundingAmount !== undefined && typeof values.fundingAmount !== 'number') {
      values.fundingAmount = Number(values.fundingAmount);
    }
    if (values.funderFeePercentage !== undefined && typeof values.funderFeePercentage !== 'number') {
      values.funderFeePercentage = Number(values.funderFeePercentage);
    }

    const entity = {
      ...loanFunderEntity,
      ...values,
      loan: loans.find(it => it.id.toString() === values.loan.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
        ...loanFunderEntity,
        loan: loanFunderEntity?.loan?.id,
      };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="flenderApp.loanFunder.home.createOrEditLabel" data-cy="LoanFunderCreateUpdateHeading">
            Create or edit a Loan Funder
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? <ValidatedField name="id" required readOnly id="loan-funder-id" label="Id" validate={{ required: true }} /> : null}
              <ValidatedField label="Loan Id" id="loan-funder-loanId" name="loanId" data-cy="loanId" type="text" />
              <ValidatedField label="Funder Id" id="loan-funder-funderId" name="funderId" data-cy="funderId" type="text" />
              <ValidatedField
                label="Funding Percentage"
                id="loan-funder-fundingPercentage"
                name="fundingPercentage"
                data-cy="fundingPercentage"
                type="text"
              />
              <ValidatedField
                label="Funding Amount"
                id="loan-funder-fundingAmount"
                name="fundingAmount"
                data-cy="fundingAmount"
                type="text"
              />
              <ValidatedField
                label="Funder Fee Percentage"
                id="loan-funder-funderFeePercentage"
                name="funderFeePercentage"
                data-cy="funderFeePercentage"
                type="text"
              />
              <ValidatedField id="loan-funder-loan" name="loan" data-cy="loan" label="Loan" type="select">
                <option value="" key="0" />
                {loans
                  ? loans.map(otherEntity => (
                    <option value={otherEntity.id} key={otherEntity.id}>
                      {otherEntity.id}
                    </option>
                  ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/loan-funder" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default LoanFunderUpdate;
