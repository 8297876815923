import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Table, Breadcrumb, BreadcrumbItem, Card, Row } from 'reactstrap';
import { getPaginationState } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity } from './loan-details.reducer';
import InfiniteScroll from 'react-infinite-scroll-component';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';

export const LoanDetailsReport = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loanDetailsList = [
    {
      'Date No': 1,
      'Date': '01/01/2024',
      'Total POS Receipts': 3432,
      'Passed Through': 3088.8,
      'Applied to Loan': 343.2,
      'Repayment Balance Opening': 40600,
      'Repayment Balance Closing': 40280.15,
      'PrimaryLender Daily Fee Opening': 15.88,
      'PrimaryLender DailyFee Closing': 0,
      'SecondaryLender DailyFee Opening': 7.47,
      'SecondaryLender DailyFee Closing': 0,
      'PrimaryLender Principal Paid': 271.87,
      'SecondaryLender Principal Paid': 47.98,
      'PrimaryLender Daily Fee Paid': 15.88,
      'SecondaryLender Daily Fee Paid': 7.47,
      'Total Principal Paid': 319.85,
      'Total DailyFee Paid': 23.35,
    },
    {
      'Date No': 2,
      'Date': '01/02/2024',
      'Total POS Receipts': 2447,
      'Passed Through': 2202.3,
      'Applied to Loan': 244.7,
      'Repayment Balance Opening': 40280.15,
      'Repayment Balance Closing': 40058.8,
      'PrimaryLender Daily Fee Opening': 15.88,
      'PrimaryLender DailyFee Closing': 0,
      'SecondaryLender DailyFee Opening': 7.47,
      'SecondaryLender DailyFee Closing': 0,
      'PrimaryLender Principal Paid': 188.15,
      'SecondaryLender Principal Paid': 33.2,
      'PrimaryLender Daily Fee Paid': 15.88,
      'SecondaryLender Daily Fee Paid': 7.47,
      'Total Principal Paid': 221.35,
      'Total DailyFee Paid': 23.35,
    },
    {
      'Date No': 3,
      'Date': '01/03/2024',
      'Total POS Receipts': 0,
      'Passed Through': 0,
      'Applied to Loan': 0,
      'Repayment Balance Opening': 40058.8,
      'Repayment Balance Closing': 40058.8,
      'PrimaryLender Daily Fee Opening': 15.88,
      'PrimaryLender DailyFee Closing': 15.88,
      'SecondaryLender DailyFee Opening': 7.47,
      'SecondaryLender DailyFee Closing': 7.47,
      'PrimaryLender Principal Paid': 0,
      'SecondaryLender Principal Paid': 0,
      'PrimaryLender Daily Fee Paid': 0,
      'SecondaryLender Daily Fee Paid': 0,
      'Total Principal Paid': 0,
      'Total DailyFee Paid': 0,
    },
    {
      'Date No': 4,
      'Date': '01/04/2024',
      'Total POS Receipts': 300,
      'Passed Through': 270,
      'Applied to Loan': 30,
      'Repayment Balance Opening': 40058.8,
      'Repayment Balance Closing': 40058.8,
      'PrimaryLender Daily Fee Opening': 31.76,
      'PrimaryLender DailyFee Closing': 1.76,
      'SecondaryLender DailyFee Opening': 14.94,
      'SecondaryLender DailyFee Closing': 14.94,
      'PrimaryLender Principal Paid': 0,
      'SecondaryLender Principal Paid': 0,
      'PrimaryLender Daily Fee Paid': 30,
      'SecondaryLender Daily Fee Paid': 0,
      'Total Principal Paid': 0,
      'Total DailyFee Paid': 30,
    },
    {
      'Date No': 5,
      'Date': '01/05/2024',
      'Total POS Receipts': 3830,
      'Passed Through': 3447,
      'Applied to Loan': 383,
      'Repayment Balance Opening': 40058.8,
      'Repayment Balance Closing': 39715.85,
      'PrimaryLender Daily Fee Opening': 17.64,
      'PrimaryLender DailyFee Closing': 0,
      'SecondaryLender DailyFee Opening': 22.41,
      'SecondaryLender DailyFee Closing': 0,
      'PrimaryLender Principal Paid': 291.51,
      'SecondaryLender Principal Paid': 51.44,
      'PrimaryLender Daily Fee Paid': 17.64,
      'SecondaryLender Daily Fee Paid': 22.41,
      'Total Principal Paid': 342.95,
      'Total DailyFee Paid': 40.05,
    },
    {
      'Date No': 5,
      'Date': '01/05/2024',
      'Total POS Receipts': 1200,
      'Passed Through': 1080,
      'Applied to Loan': 120,
      'Repayment Balance Opening': 39715.85,
      'Repayment Balance Closing': 39595.85,
      'PrimaryLender Daily Fee Opening': 0,
      'PrimaryLender DailyFee Closing': 0,
      'SecondaryLender DailyFee Opening': 0,
      'SecondaryLender DailyFee Closing': 0,
      'PrimaryLender Principal Paid': 102,
      'SecondaryLender Principal Paid': 18,
      'PrimaryLender Daily Fee Paid': 0,
      'SecondaryLender Daily Fee Paid': 0,
      'Total Principal Paid': 120,
      'Total DailyFee Paid': 0,
    },
    {
      'Date No': 6,
      'Date': '01/06/2024',
      'Total POS Receipts': 200,
      'Passed Through': 180,
      'Applied to Loan': 20,
      'Repayment Balance Opening': 39595.85,
      'Repayment Balance Closing': 39595.85,
      'PrimaryLender Daily Fee Opening': 15.88,
      'PrimaryLender DailyFee Closing': 0,
      'SecondaryLender DailyFee Opening': 7.47,
      'SecondaryLender DailyFee Closing': 3.35,
      'PrimaryLender Principal Paid': 0,
      'SecondaryLender Principal Paid': 0,
      'PrimaryLender Daily Fee Paid': 15.88,
      'SecondaryLender Daily Fee Paid': 4.12,
      'Total Principal Paid': 0,
      'Total DailyFee Paid': 20,
    },
  ];

  const pageLocation = useLocation();
  const links = useAppSelector(state => state.loanDetails.links);
  const loading = useAppSelector(state => state.loanDetails.loading);
  const { id } = useParams<'id'>();
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );

  useEffect(() => {
    dispatch(getEntity(id));
  }, [dispatch, id]);

  const handleLoadMore = () => {
    if ((window as any).pageYOffset > 0) {
      setPaginationState({
        ...paginationState,
        activePage: paginationState.activePage + 1,
      });
    }
  };

  return (
    <>
      <div>
        <Breadcrumb>
          <BreadcrumbItem>
            <a onClick={() => navigate('/')}>Home</a>
          </BreadcrumbItem>
          <BreadcrumbItem>Repayment Schedule</BreadcrumbItem>
        </Breadcrumb>
      </div>
      <Row>
        <div className="table-responsive">
          <InfiniteScroll
            dataLength={loanDetailsList ? loanDetailsList.length : 0}
            next={handleLoadMore}
            hasMore={paginationState.activePage - 1 < links.next}
            loader={<div className="loader">Loading ...</div>}
          >
            <Card className="mb-3">
              <div style={{ display: 'flex', flexDirection: 'row', width: '50%' }}>
                <h6 style={{ color: '#000', width: '250px' }}>Borrower Name</h6>
                <h6 style={{ color: '#000', width: '50px' }}> : </h6>
                <h6 style={{ color: '#000' }}> Thomas</h6>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', width: '50%' }}>
                <h6 style={{ color: '#000', width: '250px' }}>Borrower Address</h6>
                <h6 style={{ color: '#000', width: '50px' }}> : </h6>
                <h6 style={{ color: '#000' }}> Thomas Villa, Bantry, County Cork</h6>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', width: '50%' }}>
                <h6 style={{ color: '#000', width: '250px' }}>Loan Ref</h6>
                <h6 style={{ color: '#000', width: '50px' }}> : </h6>
                <h6 style={{ color: '#000' }}> 48751369</h6>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', width: '50%' }}>
                <h6 style={{ color: '#000', width: '250px' }}>Loan Activation Date</h6>
                <h6 style={{ color: '#000', width: '50px' }}> : </h6>
                <h6 style={{ color: '#000' }}> 01/01/2024</h6>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', width: '50%' }}>
                <h6 style={{ color: '#000', width: '250px' }}>POS Holdback Rate</h6>
                <h6 style={{ color: '#000', width: '50px' }}> : </h6>
                <h6 style={{ color: '#000' }}> 10%</h6>
              </div>
            </Card>
            {loanDetailsList && loanDetailsList.length > 0 ? (
              <Table responsive>
                <thead>
                  <tr>
                    <th>Date No</th>
                    <th>Date</th>
                    <th>Total POS Receipts</th>
                    <th>Passed Through</th>
                    <th>Applied to Loan</th>
                    <th>Repayment Balance Opening</th>
                    <th>Repayment Balance Closing</th>
                    <th>PrimaryLender Daily Fee Opening</th>
                    <th>PrimaryLender DailyFee Closing</th>
                    <th>SecondaryLender DailyFee Opening</th>
                    <th>SecondaryLender DailyFee Closing</th>
                    <th>PrimaryLender Principal Paid</th>
                    <th>SecondaryLender Principal Paid</th>
                    <th>PrimaryLender Daily Fee Paid</th>
                    <th>SecondaryLender Daily Fee Paid</th>
                    <th>Total Principal Paid</th>
                    <th>Total DailyFee Paid</th>
                  </tr>
                </thead>
                <tbody>
                  {loanDetailsList.map((data, i) => (
                    <React.Fragment key={`entity-${i}`}>
                      <tr data-cy="entityTable">
                        <td>{data['Date No']}</td>
                        <td>{data['Date']}</td>
                        <td>{data['Total POS Receipts']}</td>
                        <td>{data['Passed Through']}</td>
                        <td>{data['Applied to Loan']}</td>
                        <td>{data['Repayment Balance Opening']}</td>
                        <td>{data['Repayment Balance Closing']}</td>
                        <td>{data['PrimaryLender Daily Fee Opening']}</td>
                        <td>{data['PrimaryLender DailyFee Closing']}</td>
                        <td>{data['SecondaryLender DailyFee Opening']}</td>
                        <td>{data['SecondaryLender DailyFee Closing']}</td>
                        <td>{data['PrimaryLender Principal Paid']}</td>
                        <td>{data['SecondaryLender Principal Paid']}</td>
                        <td>{data['PrimaryLender Daily Fee Paid']}</td>
                        <td>{data['SecondaryLender Daily Fee Paid']}</td>
                        <td>{data['Total Principal Paid']}</td>
                        <td>{data['Total DailyFee Paid']}</td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </Table>
            ) : (
              !loading && <div className="alert alert-warning">No Loan Details found</div>
            )}
          </InfiniteScroll>
        </div>
      </Row>
    </>
  );
};

export default LoanDetailsReport;
