import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import {} from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './loan-funder.reducer';

export const LoanFunderDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const loanFunderEntity = useAppSelector(state => state.loanFunder.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="loanFunderDetailsHeading">Loan Funder</h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">Id</span>
          </dt>
          <dd>{loanFunderEntity.id}</dd>
          <dt>
            <span id="loanId">Loan Id</span>
          </dt>
          <dd>{loanFunderEntity.loanId}</dd>
          <dt>
            <span id="funderId">Funder Id</span>
          </dt>
          <dd>{loanFunderEntity.funderId}</dd>
          <dt>
            <span id="fundingPercentage">Funding Percentage</span>
          </dt>
          <dd>{loanFunderEntity.fundingPercentage}</dd>
          <dt>
            <span id="fundingAmount">Funding Amount</span>
          </dt>
          <dd>{loanFunderEntity.fundingAmount}</dd>
          <dt>
            <span id="funderFeePercentage">Funder Fee Percentage</span>
          </dt>
          <dd>{loanFunderEntity.funderFeePercentage}</dd>
          <dt>Loan</dt>
          <dd>{loanFunderEntity.loan ? loanFunderEntity.loan.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/loan-funder" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/loan-funder/${loanFunderEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
        </Button>
      </Col>
    </Row>
  );
};

export default LoanFunderDetail;
