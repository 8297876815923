import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { loadMoreDataWhenScrolled, parseHeaderForLinks } from 'react-jhipster';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { createEntitySlice, EntityState, serializeAxiosError, EntityParams } from 'app/shared/reducers/reducer.utils';
import { IGeneralLedger, defaultValue } from 'app/shared/model/general-ledger.model';
import { saveAs } from 'file-saver';

const initialState: EntityState<IGeneralLedger> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  links: { next: 0 },
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

// Actions
const apiUrl = `${SERVER_API_URL}core-banking/flender/accounts/list-of-general-ledger`;
const excelUrl = `${SERVER_API_URL}core-banking/flender/accounts/generate/gl-excel`;
const pdfUrl = `${SERVER_API_URL}core-banking/flender/accounts/generate/gl-pdf`;

export const getEntities = createAsyncThunk(
  'generalLedger/fetch_entity_list',
  async ({ startDate, endDate, page, size, sort, index, count }: EntityParams) => {
    try {
      const requestUrl = `${apiUrl}?startDate=${startDate}&endDate=${endDate}&page=${page}&size=${size}&sort=${sort}&index=${index}&count=${count}`;
      const response = await axios.get<IGeneralLedger[]>(requestUrl);
      return { data: response.data, headers: response.headers };
    } catch (error) {
      throw Error(error.message);
    }
  },
);
export const getExcelReport = createAsyncThunk('generalLedger/fetch_excel_report', async (_, { rejectWithValue }) => {
  try {
    const requestUrl = `${excelUrl}`;
    const response = await axios.get(requestUrl, {
      responseType: 'blob',
    });
    const contentDisposition = response.headers['content-disposition'];
    const filename = contentDisposition ? contentDisposition.split('filename=')[1].split(';')[0].trim() : 'general-ledger.xlsx';
    saveAs(response.data, filename);
    return { data: response.data, headers: response.headers };
  } catch (error) {
    return rejectWithValue(error.message);
  }
});
export const getPdfReport = createAsyncThunk('generalLedger/fetch_pdf_report', async (_, { rejectWithValue }) => {
  try {
    const requestUrl = `${pdfUrl}`;
    const response = await axios.get(requestUrl, {
      responseType: 'blob',
    });
    const contentDisposition = response.headers['content-disposition'];
    const filename = contentDisposition ? contentDisposition.split('filename=')[1].split(';')[0].trim() : 'general-ledger.pdf';
    saveAs(response.data, filename);
    return { data: response.data, headers: response.headers };
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const getEntity = createAsyncThunk(
  'generalLedger/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IGeneralLedger>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const createEntity = createAsyncThunk(
  'generalLedger/create_entity',
  async (entity: IGeneralLedger, thunkAPI) => {
    return axios.post<IGeneralLedger>(apiUrl, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError },
);

export const updateEntity = createAsyncThunk(
  'generalLedger/update_entity',
  async (entity: IGeneralLedger, thunkAPI) => {
    return axios.put<IGeneralLedger>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError },
);

export const partialUpdateEntity = createAsyncThunk(
  'generalLedger/partial_update_entity',
  async (entity: IGeneralLedger, thunkAPI) => {
    return axios.patch<IGeneralLedger>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError },
);

export const deleteEntity = createAsyncThunk(
  'generalLedger/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.delete<IGeneralLedger>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

// slice

export const GeneralLedgerSlice = createEntitySlice({
  name: 'generalLedger',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addCase(getExcelReport.pending, state => {
        state.errorMessage = null;
      })
      .addCase(getExcelReport.fulfilled, (state, action) => {
        state.loading = false;
        state.excelReport = action.payload;
      })
      .addCase(getExcelReport.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      })
      .addCase(getPdfReport.pending, state => {
        state.errorMessage = null;
      })
      .addCase(getPdfReport.fulfilled, (state, action) => {
        state.loading = false;
        state.excelReport = action.payload;
      })
      .addCase(getPdfReport.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;
        const links = headers && headers.link ? parseHeaderForLinks(headers.link) : '';
        return {
          ...state,
          loading: false,
          links,
          entities: loadMoreDataWhenScrolled(state.entities, data, links),
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })

      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), (state, action) => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = GeneralLedgerSlice.actions;

export default GeneralLedgerSlice.reducer;
