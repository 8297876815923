import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './loan-repayment.reducer';

export const LoanRepaymentDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const loanRepaymentEntity = useAppSelector(state => state.loanRepayment.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="loanRepaymentDetailsHeading">Loan Repayment</h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">Id</span>
          </dt>
          <dd>{loanRepaymentEntity.id}</dd>
          <dt>
            <span id="transactionId">Transaction Id</span>
          </dt>
          <dd>{loanRepaymentEntity.transactionId}</dd>
          <dt>
            <span id="loanId">Loan Id</span>
          </dt>
          <dd>{loanRepaymentEntity.loanId}</dd>
          <dt>
            <span id="customerId">Customer Id</span>
          </dt>
          <dd>{loanRepaymentEntity.customerId}</dd>
          <dt>
            <span id="loanPaymentAmount">Loan Payment Amount</span>
          </dt>
          <dd>{loanRepaymentEntity.loanPaymentAmount}</dd>
          <dt>
            <span id="paymentDate">Payment Date</span>
          </dt>
          <dd>
            {loanRepaymentEntity.paymentDate ? (
              <TextFormat value={loanRepaymentEntity.paymentDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="paymentMode">Payment Mode</span>
          </dt>
          <dd>{loanRepaymentEntity.paymentMode}</dd>
          <dt>Loan</dt>
          <dd>{loanRepaymentEntity.loan ? loanRepaymentEntity.loan.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/loan-repayment" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/loan-repayment/${loanRepaymentEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
        </Button>
      </Col>
    </Row>
  );
};

export default LoanRepaymentDetail;
